import { css } from '@emotion/react';
import Color from 'color';
import React from 'react';

import { useSpaceMatchingKey, useTheme } from '../theme';
import { Caption } from '../typography';

interface CardHeadingProps {
  title: string;
  rightAction?: React.ReactNode;
  className?: string;
}

export const CardHeading: React.FC<CardHeadingProps> = (props) => {
  const horizontalPadding = useSpaceMatchingKey('m');
  const {
    colors,
    componentProperties: { bookingManagement: bookingManagementStyles },
  } = useTheme();

  return (
    <div
      className={props.className}
      css={css({
        backgroundColor: colors.primary200,
        borderRadius: '4px 4px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        padding: `0 ${horizontalPadding}`,
        textTransform: 'uppercase',
      })}
    >
      <h1>
        <Caption
          fontFamily={
            bookingManagementStyles?.bookingCard?.header?.fontFamily
              ? 'inherit'
              : undefined
          }
        >
          {props.title}
        </Caption>
      </h1>
      <div style={{ flex: 1 }} />
      <div>{props.rightAction}</div>
    </div>
  );
};

interface CardHeadingPillProps {
  label: string;
}

export const CardHeadingPill: React.FC<CardHeadingPillProps> = (props) => {
  const {
    colors,
    keylines,
    componentProperties: { bookingManagement },
  } = useTheme();
  const space = useSpaceMatchingKey('xxs');
  const borderColor = Color(colors.secondary300).fade(0.6).toString();

  return (
    <div
      css={css`
        white-space: nowrap;
        display: flex;
        align-items: center;
        color: ${bookingManagement?.bookingCard?.header?.color ??
        colors.textPrimary};

        border: ${keylines?.border ?? `1px solid ${borderColor}`};
        margin: -8px -10px;
        padding: 8px 10px;
        border-radius: 2px;
        user-select: none;
        gap: 4px;

        > * {
          display: inline-block;
        }

        img {
          margin-inline-start: ${space};
          margin-top: 1px;
        }
      `}
    >
      <Caption>{props.label.toUpperCase()}</Caption>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="7.75" cy="7.75" r="7.25" stroke="currentColor" />
        <line
          x1="2.35355"
          y1="2.64645"
          x2="12.3536"
          y2="12.6464"
          stroke="currentColor"
        />
        <line
          x1="12.8536"
          y1="2.85355"
          x2="2.85355"
          y2="12.8536"
          stroke="currentColor"
        />
      </svg>
    </div>
  );
};
