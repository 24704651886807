import React from 'react';

import { useSpaceMatchingKey, useTheme } from '@/ui/theme';

import HotelPerks, { HotelPerksProps } from './HotelPerks.component';

export const DesktopHotelPerks = (
  props: HotelPerksProps & { className?: string }
) => {
  const { colors, componentProperties, keylines } = useTheme();
  const cardStyles = componentProperties.sidebarCards?.primary;
  const padding = useSpaceMatchingKey(cardStyles?.padding ?? 'l');

  const { perks } = props;

  if (!perks || perks.length <= 0) {
    return null;
  }

  return (
    <div
      className={props.className}
      css={{
        ...cardStyles,
        backgroundColor: colors[cardStyles?.backgroundColor ?? 'primary200'],
        padding,
        color: '#3e3e3e',
        border:
          keylines?.border ??
          (cardStyles?.borderColor
            ? `1px solid ${colors[cardStyles.borderColor]}`
            : 'unset'),
      }}
    >
      <HotelPerks {...props} />
    </div>
  );
};

export default DesktopHotelPerks;
