import { V1GetCalendarRequest } from '@ennismore/booking-api-client';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { formatDate, getTwoMonthDateRange } from '../helpers/date';
import { useCalendarAvailability } from './use-calendar-availability.hook';

interface UseUnavailableDaysProps
  extends Omit<V1GetCalendarRequest, 'startDate' | 'endDate'> {
  selectedMonth?: string;
  checkinDate?: string;
  checkoutDate?: string;
}

export const useUnavailableDays = (props: UseUnavailableDaysProps) => {
  const { selectedMonth, checkinDate, checkoutDate, ...rest } = props;

  const { startDate, endDate } = getTwoMonthDateRange(
    selectedMonth || checkinDate || formatDate(dayjs())
  );

  const { data: calendarAvailability, isLoading } = useCalendarAvailability({
    ...rest,
    startDate,
    endDate,
  });

  const unavailableDatesSet = useMemo(() => {
    if (!calendarAvailability?.dates) return new Set<string>();

    const unavailableDates = calendarAvailability.dates
      .filter((item) => !item.available)
      .map((item) => item.date);

    const isCheckinDateAvailable = Boolean(
      checkinDate && !unavailableDates.includes(checkinDate)
    );

    if (!checkoutDate && isCheckinDateAvailable) {
      // Identify the closest unavailable date after the check-in date and exclude it
      const closestDateAfterCheckin = unavailableDates
        .filter((date) => dayjs(date).isAfter(checkinDate))
        .sort((a, b) => dayjs(a).diff(dayjs(b)))[0];

      const filteredDates = unavailableDates.filter(
        (date) => date !== closestDateAfterCheckin
      );

      return new Set(filteredDates);
    }

    return new Set(unavailableDates);
  }, [calendarAvailability, checkinDate, checkoutDate]);

  return { unavailableDatesSet, isLoading };
};
