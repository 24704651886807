import { css } from '@emotion/react';
import type { V1BookingRoom } from '@ennismore/booking-api-client';
import React, { useEffect } from 'react';

import { useCreateBookingBasket } from '@/booking-creation/hooks/use-create-booking-basket.hook';
import { AmendBookingError } from '@/booking-management/errors/amend-booking.error';
import BookingWizardStepTitle from '@/booking/components/wizard/BookingWizardStepTitle.component';
import StepProgress from '@/booking/components/wizard/StepProgress.component';
import SearchStructuredDataContainer from '@/booking/container/SearchStructuredData.container';
import { CreateBookingError } from '@/booking/errors';
import { Box } from '@/box';
import { useActiveBrandConfig, useBrandUrls } from '@/brand';
import { OnlyMobile, ResponsiveConditional } from '@/common';
import { ContentWithSidebar } from '@/core/components';
import { useIsFetchingExchangeRate } from '@/currency-preview/hooks';
import { useAppEvents } from '@/events';
import { useHotel, useHotelQuery } from '@/hotel';
import MobileHotelPerks from '@/hox-core/components/MobileHotelPerks.component';
import { LoadingIndicator } from '@/ui/controls';
import { MobileWrapperPadding } from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import {
  BedroomAvailabilityList,
  HotelAvailabilitySearchCardContainer,
  OnSearchQueryChangeFn,
} from '../containers';
import { BedroomAvailabilitySearchErrorCard } from '../containers/BedroomAvailabilitySearchErrorCard.component';
import type { AvailabilityEvents } from '../events';
import { useModifySearchOpenState } from '../hooks';
import { useAvailabilitySearchResults } from '../hooks/use-availability-search-results.hook';
import {
  IAvailabilitySearchQueryInstance,
  availabilitySearchQuerySelectors,
} from '../models/availability-search-query.model';
import { RoomImageDisclaimer } from './RoomImageDisclaimer.component';
import { SearchResultsSidebarContainer } from './SearchResultsSidebar.container';
import { MobileModifySearchFormButton } from './controls/MobileModifySearchFormButton.component';
import { ServiceUnavailableCard } from './errors/ServiceUnavailableCard';

export interface BedroomAvailabilitySearchResultsProps {
  query: IAvailabilitySearchQueryInstance;
  /**
   * Called when the availability service is unavailable, passing a fallback URL to redirect to.
   */
  onRequestRedirect: (url: string) => void;
  title: string;

  onSearchQueryChange: OnSearchQueryChangeFn;
  onRoomSelectionComplete: (rooms: V1BookingRoom[]) => void;
  createBookingError?: CreateBookingError | AmendBookingError;
  isCreatingBooking?: boolean;

  /**
   * Should the search form be opened when rendering this component?
   */
  isSearchFormInitiallyOpen?: boolean;

  /**
   * Step progress section for mobile users. (Eg. Step 1 of 3)
   */
  mobileStepProgress: React.ReactElement<typeof StepProgress>;

  hotelReferenceId: string;
}

export const BedroomAvailabilitySearchResults: React.FC<
  BedroomAvailabilitySearchResultsProps
> = ({ query, onRequestRedirect, hotelReferenceId, ...props }) => {
  const basket = useCreateBookingBasket({ query });
  const hotel = useHotel(hotelReferenceId);

  const urls = useBrandUrls();
  const brand = useActiveBrandConfig();

  const {
    componentProperties: { search },
  } = useTheme();

  const {
    data: results,
    error,
    isLoading: isLoadingSearchResuts,
    refetch,
  } = useAvailabilitySearchResults(query, basket.nextRoomToSelect, {
    onRequestRedirect,
  });
  const [isSearchFormOpen, setIsSearchFormOpen] = useModifySearchOpenState();

  const { isLoading: isLoadingHotel } = useHotelQuery(hotelReferenceId);

  const isFetchingExchangeRate = useIsFetchingExchangeRate();

  const events = useAppEvents<AvailabilityEvents>();

  // Display loading indicator if we're fetching either the hotel or search results
  const isLoading =
    isLoadingSearchResuts || isLoadingHotel || isFetchingExchangeRate;

  // For tracking partial or no availability
  useEffect(() => {
    if (
      results &&
      hotel &&
      (results.isHotelFullyBooked ||
        results.rooms.some((room) => !room.isRoomAvailable))
    ) {
      events.emit('receivedPartialOrNoAvailability', { results, hotel, query });
    }
  }, [isLoadingSearchResuts, JSON.stringify(hotel)]);

  return (
    <ContentWithSidebar
      content={
        <main>
          <SearchStructuredDataContainer
            query={query}
            results={results}
            hotelReferenceId={hotelReferenceId}
            roomIndex={basket.selectedRooms.length}
          />

          <OnlyMobile>
            <MobileWrapperPadding>
              <div
                css={css`
                  margin-bottom: -8px;
                  margin-top: 24px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                {props.mobileStepProgress}
                <MobileModifySearchFormButton
                  onClick={() => setIsSearchFormOpen(true)}
                />
              </div>
            </MobileWrapperPadding>
          </OnlyMobile>

          {!search?.titleUnderSearchSummary ? (
            <MobileWrapperPadding>
              <BookingWizardStepTitle>{props.title}</BookingWizardStepTitle>
            </MobileWrapperPadding>
          ) : null}

          <Stack
            css={{
              rowGap: search?.results?.bedroomAvailabilityList?.rowGap,
              '@media all and (max-width: 730px)': {
                rowGap:
                  search?.results?.bedroomAvailabilityList?.mobile?.rowGap,
              },
            }}
          >
            <HotelAvailabilitySearchCardContainer
              query={query}
              onRequestOpenSearchForm={() => setIsSearchFormOpen(true)}
              onRequestCloseSearchForm={() => setIsSearchFormOpen(false)}
              isSearchFormOpen={isSearchFormOpen}
              onSearchQueryChange={props.onSearchQueryChange}
              hotelReferenceId={hotelReferenceId}
            />

            {search?.titleUnderSearchSummary ? (
              <MobileWrapperPadding>
                <BookingWizardStepTitle>{props.title}</BookingWizardStepTitle>
              </MobileWrapperPadding>
            ) : null}

            {isLoading && <LoadingIndicator style="dark" />}

            {results && !isFetchingExchangeRate && (
              <BedroomAvailabilityList
                query={query}
                results={results}
                basket={basket}
                onRequestModifySearch={() => setIsSearchFormOpen(true)}
                onRoomSelectionComplete={props.onRoomSelectionComplete}
                isCreatingBooking={props.isCreatingBooking}
                createBookingError={props.createBookingError}
                onRequestDateRangeChange={(start, end) => {
                  const selectors = availabilitySearchQuerySelectors({
                    ...query,
                    from: start,
                    to: end,
                  });

                  props.onSearchQueryChange(selectors.asURLQuery());
                }}
              />
            )}

            {/*
              If this error is classified as "service unavailable", render a card that prompts the customer to contact the hotel.
              If a brand doesn't have a contact URL setup for the current locale, fall back to rendering a
              standard error message without a contact link button as a resolution instead.
            */}
            {error &&
              (error.isServiceUnavailable &&
              brand.search.errorResolutionType === 'contact-button' &&
              urls?.contact ? (
                <ServiceUnavailableCard contactHotelUrl={urls.contact} />
              ) : (
                <BedroomAvailabilitySearchErrorCard
                  error={error}
                  onOpenSearchFormRequested={() => setIsSearchFormOpen(true)}
                  onRetryRequested={() => refetch()}
                />
              ))}

            {/* Render hotel perks in the footer for mobile users */}
            {results && hotel && (
              <ResponsiveConditional
                mobile={
                  <>
                    <OnlyMobile>
                      {/* Hide mobile perks here if this feature is enabled. We'll render it inline with results inside <BedroomAvailabilityList /> instead. */}

                      <MobileHotelPerks perks={hotel.content.perks} />

                      <MobileWrapperPadding>
                        <Box
                          css={{
                            paddingTop: '24px',
                            '@media all and (max-width: 730px)':
                              search?.results?.roomImageDisclaimer?.mobile,
                          }}
                        >
                          <RoomImageDisclaimer />
                        </Box>
                      </MobileWrapperPadding>
                    </OnlyMobile>
                  </>
                }
              />
            )}
          </Stack>
        </main>
      }
      sidebar={
        results && hotel && <SearchResultsSidebarContainer hotelModel={hotel} />
      }
    />
  );
};
