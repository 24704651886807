import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useTranslation } from '@/i18n';
import { Stack } from '@/ui/spacing';
import type { EnnismoreThemeIcons } from '@/ui/theme';
import { useTheme } from '@/ui/theme';
import { useIcon } from '@/ui/theme/hooks/use-icon';
import { BodySmall, Heading } from '@/ui/typography';

const Perk = (props: {
  title: string | React.ReactNode;
  iconSrc?: string;
  description: React.ReactNode;
}) => {
  const { componentProperties } = useTheme();
  const foregroundColor =
    componentProperties.sidebarCards?.primary?.color ?? 'textPrimary';

  return (
    <li
      css={css`
        max-width: 100%;
        .icon-wrapper {
          width: 40px;
          height: 24px;

          img {
            max-width: 24px;
            min-width: 12px;
            display: block;
          }
        }

        display: flex;
        img {
          ${componentProperties.hotelPerk?.icon?.filter &&
          `filter: ${componentProperties.hotelPerk?.icon?.filter};`}
          ${componentProperties.hotelPerk?.icon?.filter &&
          `mix-blend-mode: difference;`}
        }
      `}
    >
      {props.iconSrc && (
        <div className="icon-wrapper">
          <img className="icon" src={props.iconSrc} alt=""></img>
        </div>
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          gap: componentProperties.hotelPerk?.gap,
        }}
      >
        <BodySmall
          css={css(componentProperties.hotelPerk?.title)}
          color={foregroundColor}
        >
          {props.title}
        </BodySmall>
        <BodySmall
          css={css(componentProperties.hotelPerk?.description)}
          opacity={0.72}
          color={foregroundColor}
        >
          {props.description}
        </BodySmall>
      </div>
    </li>
  );
};

export interface HotelPerksProps {
  perks: {
    description?: string | undefined;
    icon?: EnnismoreThemeIcons;
    title: string;
  }[];
}

const HotelPerks = observer((props: HotelPerksProps) => {
  const { t } = useTranslation('search');
  const { componentProperties } = useTheme();

  const { color } = componentProperties.sidebarCards?.primary ?? {};

  return (
    <Stack
      as={'ul'}
      css={{
        rowGap:
          componentProperties.search?.results?.sidebarContainer?.hotelPerks
            ?.rowGap,
      }}
    >
      {t('perksSidebar.desktopTitle').length > 0 && (
        <Heading style="heading5" as="h5" color={color ?? 'textPrimary'}>
          {t('perksSidebar.desktopTitle')}
        </Heading>
      )}
      {props.perks.map((perk) => (
        <Perk
          title={perk.title}
          key={perk.title}
          description={perk.description}
          iconSrc={useIcon(perk.icon)}
        />
      ))}
    </Stack>
  );
});

export default HotelPerks;
