import Head from 'next/head';
import React, { useEffect } from 'react';

import { useActiveBrandConfig } from '@/brand';
import { getEnvironmentKey } from '@/env/client/config';
import { useAppEvents } from '@/events';
import { useLogger } from '@/logging';

import { GTMPageViewProvider } from '..';
import { setupFullStoryEventBindings } from '../fullstory';
import { pushToDataLayer } from '../gtm';
import { GTMV2Events, setupGTMDataLayerEventFeed } from '../gtm-v2';

interface GTMProviderProps {
  children?: React.ReactNode;
}

/**
 * Includes and initiates the GTM script in the page <Head />.
 * This should be rendered at the _document.tsx level.
 */
export const GTMProvider = ({ children }: GTMProviderProps) => {
  const containerId = useActiveBrandConfig().analytics?.gtm?.containerId;
  const environment = getEnvironmentKey();
  const logger = useLogger();
  const events = useAppEvents<GTMV2Events>();

  useEffect(() => {
    return setupGTMDataLayerEventFeed(events, (event) => {
      pushToDataLayer(event);
    });
  }, [events]);

  useEffect(() => {
    if (!window.FS) return;
    return setupFullStoryEventBindings(events, window.FS);
  }, [events]);

  // Exclude GTM from local and test environments.
  if (environment === 'local' || !containerId) {
    return <>{children}</>;
  }

  // This was originally a list of hardcoded values, but as they're soon to be sent
  //  over the wire and it will be evaluated, we're validating it twice: here and in the model.
  if (!containerId.match(/^[A-z0-9]+(?:-[A-z0-9]+)*$/)) {
    logger.error('GTM container id contained blocked characters');
    return <>{children}</>;
  }

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          if (!window.dataLayer) {
            window.dataLayer = [];
          }

          window.dataLayer.push({
            page: {
              environment: "${environment}",
            },
          })
        `,
          }}
        />
        {/* Google Tag Manager */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${containerId}');`,
          }}
        />
        {/* End Google Tag Manager */}
        {/* Google Tag Manager (noscript) */}
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        />
        {/* End Google Tag Manager (noscript) */}
      </Head>
      <GTMPageViewProvider>{children}</GTMPageViewProvider>
    </>
  );
};
