import { css } from '@emotion/react';
import Image from 'next/image';
import React from 'react';

import { Flex } from '@/box';
import { useActiveBrandConfig, useBrandUrls } from '@/brand';
import { CookieConsentButton } from '@/cookie-consent/CookieConsentButton.component';
import { CoreAppEvents } from '@/core/events';
import Copyright from '@/ennismore/components/Copyright.component';
import { useAppEvents } from '@/events';
import { useLanguage, useTranslation } from '@/i18n';
import { PageWrapper } from '@/ui/layout';
import { useTheme } from '@/ui/theme';
import { BodySmall } from '@/ui/typography';

import AnchorLink from './controls/AnchorLink.component';

const FooterLink = (props: {
  children: string;
  href: string;
  analyticsLabel: string;
}) => {
  const { colors } = useTheme();
  const events = useAppEvents<CoreAppEvents>();

  return (
    <BodySmall>
      <AnchorLink
        href={props.href}
        noUnderline
        openInNewTab
        color={colors.textPrimary}
        onClick={() =>
          events.emit('navigation', {
            location: 'Footer',
            originator: props.analyticsLabel,
          })
        }
      >
        {props.children}
      </AnchorLink>
    </BodySmall>
  );
};

export const Footer = () => {
  const brandConfig = useActiveBrandConfig();
  const urls = useBrandUrls();
  const cookieConsentConfig = brandConfig.analytics?.cookieConsent;
  const { t } = useTranslation('common');

  const { componentProperties, themeKey, keylines, colors } = useTheme();

  const language = useLanguage();

  if (!urls) {
    throw new Error(
      `Legal urls not supplied for language [${language}]. This should never happen.`
    );
  }

  return (
    <>
      {themeKey === 'orientexpress' && (
        <Flex
          justifyContent={'center'}
          paddingBottom={'120px'}
          marginTop={['120px', '0']}
        >
          <Image
            src="/brands/orientexpress/images/footer-seal.svg"
            width={126.1}
            height={135}
            alt=""
          />
        </Flex>
      )}
      <footer
        css={css({
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'center',
          borderTop: keylines?.border ?? `1px solid ${colors.primary300}`,
          ...componentProperties.footer,
          span: {
            ...componentProperties.footer,
            borderTop: 'none',
          },
          a: {
            ...componentProperties.footer,
            borderTop: 'none',
          },
          '@media all and (max-width: 730px)': {
            marginTop: componentProperties.footer?.mobile?.marginTop ?? '40px',
            'span,a': {
              fontSize: componentProperties.footer?.mobile?.fontSize,
              lineHeight: componentProperties.footer?.mobile?.lineHeight,
            },
          },
        })}
        role="contentinfo"
      >
        <div
          css={{
            width: '100%',
            maxWidth: '1320px',
          }}
        >
          <PageWrapper>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '24px 0',
                '@media all and (max-width: 975px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <ul
                css={{
                  display: 'flex',
                  columnGap: '72px',
                  flexWrap: 'wrap',
                  '@media all and (max-width: 975px)': {
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 0,
                  },
                  '@media all and (max-width: 730px)': {
                    rowGap:
                      componentProperties.footer?.link?.mobile?.gap ?? '16px',
                  },
                  '@media all and (max-width: 500px)': {
                    gridTemplateColumns: '1fr',
                  },
                }}
              >
                <li>
                  <FooterLink
                    href={urls.privacyPolicy}
                    analyticsLabel="Privacy Policy"
                  >
                    {t('legal.privacyPolicy')}
                  </FooterLink>
                </li>
                <li>
                  <FooterLink
                    href={urls.cookiePolicy}
                    analyticsLabel="Cookie Policy"
                  >
                    {t('legal.cookiePolicy')}
                  </FooterLink>
                </li>
                {cookieConsentConfig && (
                  <li>
                    <BodySmall>
                      {/* TODO: weirdly styling blends in perfectly without any CSS applied,
                    to revisit so that styles are definitely inherited from the FooterLinks surounding this. */}
                      <CookieConsentButton config={cookieConsentConfig}>
                        {t('legal.cookieSettings')}
                      </CookieConsentButton>
                    </BodySmall>
                  </li>
                )}
                <li>
                  <FooterLink
                    href={urls.termsOfUse}
                    analyticsLabel="Terms of Use"
                  >
                    {t('legal.termsOfUse')}
                  </FooterLink>
                </li>
                {urls.accessibility && (
                  <li>
                    <FooterLink
                      href={urls.accessibility}
                      analyticsLabel="Accessibility"
                    >
                      {t('legal.accessibility')}
                    </FooterLink>
                  </li>
                )}
              </ul>
              <div
                css={{
                  alignSelf: 'center',
                  '@media all and (max-width: 975px)': {
                    display: 'flex',
                    marginInlineStart: '50%',
                    marginTop: '12px',
                    alignSelf: 'normal',
                  },
                  '@media all and (max-width: 730px)': {
                    marginTop: '12px',
                  },
                  '@media all and (max-width: 500px)': {
                    marginTop: '48px',
                    marginInlineStart: '0',
                    justifyContent: 'center',
                  },
                }}
              >
                <Copyright />
              </div>
            </div>
          </PageWrapper>
        </div>
      </footer>
    </>
  );
};

export default Footer;
