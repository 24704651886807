import { z } from 'zod';

import { ImageModel } from '@/common/models/image.model';

export type IRestaurantModel = typeof RestaurantModel;
export type IRestaurantModelInstance = z.infer<IRestaurantModel>;

export const RestaurantModel = z.object({
  name: z.string(),
  publicUrl: z.string(),
  description: z.string().optional(),
  images: z.array(ImageModel).default([]),
});
