/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { getEnvironmentKey } from '@/env/client/config';
import { Card } from '@/ui/layout';
import { BodyCopy } from '@/ui/typography';

import {
  type CookieConsentStatus,
  CookieConsentStatusContext,
  cookieConsentStatusInitialState,
} from '../consent-status';

/**
 * Includes the OneTrust script to be placed within the <Head />.
 * This should be rendered at the _app.tsx level as it requires access to the brand config,
 * which is then used to supply this component with the OneTrust ID.
 */
export const OneTrustScript = ({
  id: oneTrustID,
}: {
  id: string | undefined;
}) => {
  if (!oneTrustID) return null;

  const environment = getEnvironmentKey();

  const testEnvironment = environment !== 'live';

  return (
    <>
      {/* Asynchronously loaded OneTrust Cookies Consent Notice */}
      <script
        async
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script={`${oneTrustID}${testEnvironment ? '-test' : ''}`}
      />
    </>
  );
};

export const OneTrustCookieSettingsButton = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <a
    href="#cookie-banner"
    onClick={() => {
      window.Optanon?.ToggleInfoDisplay();
    }}
  >
    {children}
  </a>
);

export const OneTrustSideEffects = (props: { id: string }) => {
  const [oneTrustProviderLoaded, setOneTrustProviderLoaded] = useState(() =>
    Boolean(window?.OneTrust)
  );

  // Track OneTrust load state
  useEffect(() => {
    const handler = () => setOneTrustProviderLoaded(true);

    window.addEventListener('one-trust-loaded', handler, {
      once: true,
    });

    return () => window.removeEventListener('one-trust-loaded', handler);
  }, [setOneTrustProviderLoaded]);

  const router = useRouter();
  const toast = useToast();
  const preferenceSyncSuccess = useRef<boolean>(false);

  /**
   * Syncs cross-domain consent and hides the banner if the required consent has already been given.
   */
  useEffect(() => {
    if (!oneTrustProviderLoaded || preferenceSyncSuccess.current) return;

    const url = new URL(window.location.href);
    const identifier = url.searchParams.get('identifier');
    const domain = url.searchParams.get('domain');
    const oneTrustActiveGroups = url.searchParams.get('oneTrustActiveGroups');

    if (oneTrustActiveGroups && identifier && domain) {
      // If the banner is not shown, either the browser is blocking OneTrust or the user has already set their prefs.
      if (!window.OneTrust.IsAlertBoxClosed()) {
        window.OneTrust.setDataSubjectId(identifier);

        const cookieCategoryGroups = window.OneTrust.GetDomainData()
          ?.Groups as { OptanonGroupId: string; PurposeId: string }[];
        const purposes: { Id: string; TransactionType: string }[] = [];

        Array.from(cookieCategoryGroups).forEach((cookieCategoryGroupsItem) => {
          // in the Domain Data JSON the OptanonGroupId is expressed in C00X
          if (
            oneTrustActiveGroups.includes(
              cookieCategoryGroupsItem?.OptanonGroupId
            ) &&
            cookieCategoryGroupsItem?.OptanonGroupId !== 'C0001'
          ) {
            const purposeId = cookieCategoryGroupsItem?.PurposeId;
            const purpose = { Id: purposeId, TransactionType: 'CONFIRMED' };
            purposes.push(purpose);
          }
        });

        // Set consent
        Array.from(purposes).forEach((purpose) => {
          window.Optanon?.setConsentProfile({ purposes: [purpose] });
        });

        // Close the OneTrust window
        window.Optanon?.Close();
      }

      /**
       * Remove the three variables from the URL to prevent automatically
       * granting consent for users clicking on a shared url, e.g. through social media.
       */
      ['identifier', 'domain', 'oneTrustActiveGroups'].forEach((param) =>
        url.searchParams.delete(param)
      );

      void router.replace(url.href, undefined, { shallow: true });

      toast({
        title: 'Cookie Consent',
        render: () => (
          <Card>
            <BodyCopy>
              {/*
                Will translate this once we agree on copy here - this came from the original
                OneTrust code, but think it could do with some refining.
              */}
              {`Cookie preferences synced between ${domain} and ${window.location.hostname}. `}
              <OneTrustCookieSettingsButton>{`(Edit)`}</OneTrustCookieSettingsButton>
            </BodyCopy>
          </Card>
        ),
      });

      preferenceSyncSuccess.current = true;
    }
  }, [props.id, oneTrustProviderLoaded, router, toast]);

  return null;
};

export function determineStatus() {
  if (
    // This eslint rule is misleading - an optional chain functions differently than this code.
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    typeof window === 'undefined' ||
    typeof window.OnetrustActiveGroups === 'undefined'
  ) {
    return cookieConsentStatusInitialState;
  }

  const activeGroups = window.OnetrustActiveGroups;

  // These codes have been verified as consistent across all domain configurations at the time of writing.
  // They may change in the future.
  // More details: https://ennismore.atlassian.net/wiki/spaces/BP/pages/3820257284/Cookie+Consent+Status
  return {
    necessary: activeGroups.includes('C0001'),
    performance: activeGroups.includes('C0002'),
    functional: activeGroups.includes('C0003'),
    targeting: activeGroups.includes('C0004'),
  };
}

export function OneTrustConsentStatusProvider(props: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<CookieConsentStatus>(() =>
    determineStatus()
  );

  if (typeof window !== 'undefined') {
    // No cleanup needed as this will be overwritten on each render
    window.OptanonWrapper = () => {
      setState(determineStatus());
      window.dispatchEvent(new Event('one-trust-loaded'));
    };
  }

  return (
    <CookieConsentStatusContext.Provider value={state}>
      {props.children}
    </CookieConsentStatusContext.Provider>
  );
}
