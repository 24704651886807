import type { EnnismoreTheme } from '@/ui/theme';

import { delanoTheme } from './delano.theme';

export const delanoDubaiTheme: EnnismoreTheme = {
  ...delanoTheme,
  logoUrl: '/brands/delano/images/delano-dubai-logo.svg',
  componentProperties: {
    ...delanoTheme.componentProperties,
    header: {
      ...delanoTheme.componentProperties?.header,
      logo: {
        ...delanoTheme.componentProperties?.header?.logo,
        paddingBlock: '24px',
        paddingInline: '80px 0',
      },
    },
  },
};
