import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import da from './locale/da.json';
import de from './locale/de.json';
import enGB from './locale/en-GB.json';
import fr from './locale/fr.json';
import it from './locale/it.json';

export const twentyFiveHoursBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: '25hours',
  themeKey: '25hours',
  name: '25hours',
  // defaultHotelCode: '25hours.dastour-dusseldorf',
  hotels: [
    {
      referenceId: '25hours.dastour-dusseldorf',
      code: '25hours.dastour-dusseldorf',
      name: '25hours Hotel Das Tour',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.west-zurich',
      code: '25hours.west-zurich',
      name: '25hours Hotel Zürich West',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.piazzasanpaolino-florence',
      code: '25hours.piazzasanpaolino-florence',
      name: '25hours Hotel Piazza San Paolino',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.langstrasse-zurich',
      code: '25hours.langstrasse-zurich',
      name: '25hours Hotel Langstrasse',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.theroyalbavarian-munich',
      code: '25hours.theroyalbavarian-munich',
      name: '25hours Hotel The Royal Bavarian',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.thetrip-frankfurt',
      code: '25hours.thetrip-frankfurt',
      name: '25hours Hotel The Trip',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.indreby-copenhagen',
      code: '25hours.indreby-copenhagen',
      name: '25hours Hotel Indre By',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.thegoldman-frankfurt',
      code: '25hours.thegoldman-frankfurt',
      name: '25hours Hotel The Goldman',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.thecircle-cologne',
      code: '25hours.thecircle-cologne',
      name: '25hours Hotel The Circle',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.beimmuseumquartier-vienna',
      code: '25hours.beimmuseumquartier-vienna',
      name: '25hours Hotel beim MuseumsQuartier',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.alteshafenamt-hamburg',
      code: '25hours.alteshafenamt-hamburg',
      name: '25hours Hotel Altes Hafenamt',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.bikiniberlin-berlin',
      code: '25hours.bikiniberlin-berlin',
      name: '25hours Hotel Bikini Berlin',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.hafencity-hamburg',
      code: '25hours.hafencity-hamburg',
      name: '25hours Hotel HafenCity',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.onecentral-dubai',
      code: '25hours.onecentral-dubai',
      name: '25hours Hotel One Central',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.terminusnord-paris',
      code: '25hours.terminusnord-paris',
      name: '25hours Hotel Terminus Nord',
      dialect: 'ohip',
    },
    {
      referenceId: '25hours.paperisland-copenhagen',
      code: '25hours.paperisland-copenhagen',
      name: '25hours Hotel Paper Island',
      dialect: 'ohip',
      openingDate: '2024-07-27',
    },
    {
      referenceId: '25hours.theoddbird-jakarta',
      code: '25hours.theoddbird-jakarta',
      name: '25hours Hotel The Oddbird',
      dialect: 'ohip',
      openingDate: '2024-11-07',
    },
  ],
  homeUrl: 'https://25hours-hotels.com',
  domains: [
    'booking.25hours.local',
    '25hours.dev.env.ennismore.com',
    '25hours-sst.dev.env.ennismore.com',
    '25hours-sst.staging.env.ennismore.com',
    '25hours-sst.live.env.ennismore.com',
    '25hours.staging.env.ennismore.com',
    'booking.25hours-hotels.com',
    'book.25hours-hotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'de', 'fr', 'da', 'it'],
  },

  maxRoomCountPerBooking: 7,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://25hours-hotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://25hours-hotels.com/legal/terms-and-policies/cookies-policy/',
        termsOfUse:
          'https://25hours-hotels.com/legal/terms-and-policies/terms-and-conditions-of-services/',
        contact: 'https://25hours-hotels.com/contact/',
      },
      de: {
        privacyPolicy:
          'https://25hours-hotels.com/de/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://25hours-hotels.com/de/legal/terms-and-policies/cookies-policy/',
        termsOfUse:
          'https://25hours-hotels.com/de/legal/terms-and-policies/terms-and-conditions-of-services/',
        contact: 'https://25hours-hotels.com/de/contact/',
      },
      fr: {
        privacyPolicy:
          'https://25hours-hotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://25hours-hotels.com/legal/terms-and-policies/cookies-policy/',
        termsOfUse:
          'https://25hours-hotels.com/legal/terms-and-policies/terms-and-conditions-of-services/',
        contact: 'https://25hours-hotels.com/contact/',
      },
      da: {
        privacyPolicy:
          'https://25hours-hotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://25hours-hotels.com/legal/terms-and-policies/cookies-policy/',
        termsOfUse:
          'https://25hours-hotels.com/legal/terms-and-policies/terms-and-conditions-of-services/',
        contact: 'https://25hours-hotels.com/contact/',
      },
      it: {
        privacyPolicy:
          'https://25hours-hotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://25hours-hotels.com/legal/terms-and-policies/cookies-policy/',
        termsOfUse:
          'https://25hours-hotels.com/legal/terms-and-policies/terms-and-conditions-of-services/',
        contact: 'https://25hours-hotels.com/contact/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.DE,
    CountryCountry.AT,
    CountryCountry.CH,
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.DK,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: false,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
    de,
    fr,
    da,
    it,
  },

  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-TQX8X7H' : 'GTM-PDHXGHRQ',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '01915b44-9aa0-7576-8aed-6decac478450',
    },
  },
  supportedCurrencies: ['GBP', 'EUR', 'USD', 'CHF', 'AED', 'DKK'],
  maxChildAge: 11,
});
