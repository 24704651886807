import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'gt-walsheim-pro-medium, serif',
  headlineBold: 'gt-walsheim-pro-bold, serif',
  intro: 'trade-gothic-bold, sans-serif',
  subheader: 'trade-gothic-bold, sans-serif',
  body: 'gt-walsheim-pro-regular, serif',
};

export const mamaShelterTheme: EnnismoreTheme = {
  themeKey: 'mamashelter',
  logoUrl: '/brands/mamashelter/icons/logo.svg',
  faviconUrl: '/brands/mamashelter/icons/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontWeight: 500,
      // Original theme styles:
      // fontSize: '80px',
      // lineHeight: '88px',
      fontSize: '48px',
      lineHeight: '56px',
    },
    heading2: {
      fontFamily: fonts.headline,
      fontSize: '56px',
      lineHeight: '64px',
    },
    heading3: {
      fontFamily: fonts.headline,
      fontSize: '40px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.headline,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.headline,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '20px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '24px',
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: '#fff',
    textPrimary: '#000',
    textSecondary: '#80807b',
    background: '#F1F1F1',
    primary100: '#f7f7e1', // glen only - ignore
    primary200: '#f7f7e1',
    primary300: '#2d2926',
    primary400: '#FFE7D9', // hox only
    primary500: '#2d2926',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#2d2926',
    secondary400: '#2d2926',
    error: '#B00020',
  },
  buttons: {
    primary: {
      backgroundColor: '#000',
      fontFamily: fonts.headlineBold,
      textTransform: 'uppercase',
      fontSize: '20px',
      borderRadius: 5,
      lineHeight: '36px',
      transition: 'background-color .3s ease, color .3s ease',
      ':hover': {
        backgroundColor: '#FFEA00',
        color: '#000',
      },
    },
    secondary: {
      backgroundColor: 'transparent',
      fontFamily: fonts.headlineBold,
      textTransform: 'uppercase',
      fontSize: '20px',
      borderRadius: 5,
      lineHeight: '36px',
      border: '2px solid black',
      color: '#000',
      transition: 'background-color .3s ease, color .3s ease',
      ':hover': {
        backgroundColor: '#FFEA00',
        color: '#000',
      },
    },
  },
  keylines: {
    border: '1px solid #000',
  },
  forms: {
    input: { backgroundColor: 'white' },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'gt-walsheim-pro-medium',
      fontWeight: 500,
      sources: {
        woff: '/brands/mamashelter/fonts/gt-walsheim-pro-medium.woff2',
      },
    },
    {
      fontFamily: 'gt-walsheim-pro-bold',
      fontWeight: 700,
      sources: {
        woff: '/brands/mamashelter/fonts/gt-walsheim-pro-bold.woff2',
      },
    },
    {
      fontFamily: 'trade-gothic-bold',
      fontWeight: 700,
      sources: {
        woff: '/brands/mamashelter/fonts/trade-gothic-bold.woff2',
      },
    },
    {
      fontFamily: 'gt-walsheim-pro-regular',
      fontWeight: 400,
      sources: {
        woff: '/brands/mamashelter/fonts/gt-walsheim-pro-regular.woff2',
      },
    },
    {
      fontFamily: 'montserrat-regular',
      fontWeight: 400,
      sources: {
        woff: '/brands/mamashelter/fonts/montserrat-v25-latin-regular.woff2',
      },
    },
  ],
  componentProperties: {
    sidebarCards: {
      primary: {
        borderRadius: '5px',
        backgroundColor: 'white',
        borderColor: 'textPrimary',
      },
      secondary: {
        borderRadius: '5px',
        backgroundColor: 'white',
        borderColor: 'textPrimary',
      },
    },
    cards: {
      primary: {
        border: '1px solid #000',
        borderRadius: '5px',
        mobile: {
          border: 'none',
        },
      },
    },
    header: {
      backgroundColor: 'white',
      wizard: {
        step: {
          active: {
            fontFamily: fonts.headline,
          },
        },
      },
    },
    footer: {
      backgroundColor: '#FFEA00',
    },
    calendar: {
      header: {
        backgroundColor: '#FFEA00',
        caption: {
          fontFamily: fonts.body,
        },
      },
    },
    confirmation: {
      header: {
        backgroundColor: '#FFEA00',
      },
    },
    bookingManagement: {
      pageWrapper: {
        login: {
          alignContent: 'left',
        },
        verify: {
          alignContent: 'left',
        },
      },
      bookingCard: {
        header: {
          backgroundColor: 'white',
          fontFamily: fonts.headline,
          borderBottom: '1px solid black',
        },
      },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
