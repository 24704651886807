import type { EnnismoreTheme } from '@/ui/theme';

const fonts = {
  headline: 'ABCProphet, sans-serif',
  intro: 'ABCProphet, sans-serif',
  subheader: 'ABCProphet, sans-serif',
  body: 'ABCProphet, sans-serif',
};

export const mondrianTheme: EnnismoreTheme = {
  themeKey: 'mondrian',
  logoUrl: '/brands/mondrian/logo.svg',
  faviconUrl: '/brands/mondrian/favicon.png',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '32px',
      textTransform: 'uppercase',
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '64px',
      fontWeight: 300,
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '24px',
      lineHeight: '32px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '16px',
      lineHeight: '28px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 300,
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: 'white',
    textPrimary: '#1A1A1A',
    textSecondary: '#999999',
    background: '#CBD6DF',
    primary100: '#f7f7e1',
    primary200: 'white',
    primary300: '#74767B',
    primary400: '#e8e8e8',
    primary500: '#74767B',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#74767B',
    secondary400: '#74767B',
    error: '#B00020',
  },
  buttons: {
    primary: {
      backgroundColor: '#3F3AED',
      fontFamily: fonts.subheader,
      fontSize: '16px',
      borderRadius: 4,
      fontWeight: 'normal',
      textTransform: 'uppercase',
      transition: 'opacity .2s ease-in-out',
      ':hover': {
        opacity: 0.7,
      },
    },
  },
  keylines: {
    border: '1px solid #999999',
  },
  forms: {
    heading: {
      fontSize: '24px',
      textTransform: 'uppercase',
      color: '#3F3AED',
      mobile: {
        fontSize: '18px',
      },
    },
    input: { fontSize: '16px', backgroundColor: 'white', borderRadius: '4px' },
    label: {
      fontSize: '16px',
    },
    radioButton: {
      description: {
        color: '#1A1A1A',
      },
    },
    radioGroup: {
      divider: {
        border: '1px solid #E9E9E9',
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'ABCProphet',
      fontWeight: 300,
      fontStyle: 'normal',
      sources: {
        woff2: '/brands/mondrian/fonts/ABCProphet-Light.woff2',
      },
    },
    {
      fontFamily: 'ABCProphet',
      fontWeight: 400,
      sources: {
        woff2: '/brands/mondrian/fonts/ABCProphet-Regular.woff2',
      },
    },
    {
      fontFamily: 'ABCProphet',
      fontWeight: 400,
      fontStyle: 'italic',
      sources: {
        woff2: '/brands/mondrian/fonts/ABCProphet-RegularItalic.woff2',
      },
    },
  ],
  componentProperties: {
    confirmation: {
      title: {
        color: '#3F3AED',
        fontSize: '32px',
        textTransform: 'uppercase',
        mobile: {
          fontSize: '26px',
        },
      },
      confirmationMessage: {
        color: '#3F3AED',
        fontSize: '18px',
      },
      bookingConfirmationDetailsCard: {
        bookingNumberHeading: {
          color: '#3F3AED',
        },
      },
      header: {
        backgroundColor: '#E8E7AA',
      },
      bookingConfirmationAdditionalDetailsContainer: {
        divider: {
          borderColor: '#E9E9E9',
        },
      },
      headerImage: {
        height: '480px',
        objectFit: 'cover',
        container: {
          margin: '0',
          width: '100%',
          paddingBottom: '48px',
        },
      },
      anyQuestionsCard: {
        title: {
          textTransform: 'uppercase',
          color: '#3F3AED',
          mobile: {
            fontSize: '18px',
          },
        },
      },
      restaurantsCard: {
        title: {
          textTransform: 'uppercase',
          color: '#3F3AED',
          mobile: {
            fontSize: '18px',
          },
        },
        restaurantTitle: {
          textTransform: 'uppercase',
        },
      },
      whereToFindUsCard: {
        title: {
          textTransform: 'uppercase',
          color: '#3F3AED',
          mobile: {
            fontSize: '18px',
          },
        },
        planYourJourneyLink: {
          color: '#1A1A1A',
        },
      },
    },
    consentInputs: {
      title: {
        display: 'none',
      },
    },
    header: {
      logo: {
        borderRight: 'none',
      },
      wizard: {
        step: {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 300,
          active: {
            fontWeight: 400,
          },
        },
      },
    },
    footer: {
      backgroundColor: '#3F3AED',
      color: '#E8E7AA',
    },
    search: {
      results: {
        sidebarContainer: {
          cancellationPolicy: {
            backgroundColor: 'transparent',
            border: '1px solid white',
            borderRadius: '6px',
            color: '#1A1A1A',
            readMoreButton: {
              fontWeight: 300,
            },
            mobile: {
              color: '#1A1A1A',
              textTransform: 'uppercase',
            },
          },
          hotelPerks: {
            border: 'none',
            mobile: {
              title: {
                textTransform: 'uppercase',
              },
              button: {
                textTransform: 'uppercase',
              },
            },
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          backgroundColor: '#EAEEF1',
          border: 'none',
          borderRadius: '6px',
          expanded: {
            borderBottom: 'none',
            backgroundColor: 'white',
            borderRadius: '6px 6px 0 0',
          },
          mobile: {
            padding: '28px',
            title: {
              fontSize: '18px',
              color: '#3F3AED',
              textTransform: 'uppercase',
            },
          },
          copy: { fontSize: '14px' },
          modifySearchButton: {
            textTransform: 'uppercase',
            fontSize: '14px',
          },
        },
        searchForm: {
          border: 'none',
          borderTop: 'none',
          borderRadius: '0 0 6px 6px',
        },
      },
    },
    modals: {
      title: {
        textTransform: 'uppercase',
      },
    },
    calendar: {
      header: {
        backgroundColor: '#E8E7AA',
        caption: {
          textTransform: 'uppercase',
        },
      },
      body: {
        date: { selected: { backgroundColor: '#3F3AED' } },
      },
    },
    sidebarCards: {
      primary: {
        borderRadius: '0px',
        borderColor: 'white',
      },
      secondary: {
        borderRadius: '0px',
        borderColor: 'primary300',
      },
    },
    checkoutSidebar: {
      cancellationPolicy: {
        description: {
          color: '#1A1A1A',
        },
        border: '1px solid white',
        borderRadius: '6px',
      },
    },
    staySummary: {
      border: 'none',
      summarySection: {
        padding: '16px 24px',
      },
      dataRow: {
        label: {
          opacity: 1,
          fontSize: '16px',
        },
        value: {
          fontSize: '16px',
          fontWeight: 400,
        },
      },
      title: {
        color: '#3F3AED',
        padding: '18px 0 18px',
        textTransform: 'uppercase',
        fontSize: '24px',
        mobile: {
          fontSize: '18px',
        },
      },
      dropdown: {
        textTransform: 'uppercase',
      },
      divider: {
        borderColor: '#E9E9E9',
      },
    },
    imageSlider: {
      paginator: {
        color: '#3F3AED',
      },
    },
    bedroomCard: {
      roomTitle: {
        color: '#3F3AED',
        textTransform: 'uppercase',
      },
      roomSpecItem: {
        color: '#3D3937B8',
        value: {
          color: '#1A1A1A',
          fontWeight: 400,
        },
      },
      roomRateName: {
        color: '#3F3AED',
        fontSize: '18px',
        textTransform: 'uppercase',
        fontWeight: 300,
      },
    },
    cards: {
      primary: {
        border: 'none',
        divider: {
          borderColor: '#E9E9E9',
        },
      },
    },
    page: {
      background: '#CBD6DF',
    },
    hotelPerk: {
      title: { fontWeight: 400 },
      description: {
        color: '#999999',
        opacity: 1,
      },
      icon: {
        filter: 'brightness()',
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
  },

  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
