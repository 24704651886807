import { css } from '@emotion/react';
import React, { useMemo } from 'react';

import { useLanguage } from '@/i18n';

import { useTheme } from '../theme';

export const LocalisedFontProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { localisedFonts } = useTheme();

  const language = useLanguage();

  const fontStyles = useMemo(() => {
    const fontSet = localisedFonts?.[language];

    if (!fontSet) return null;

    return css`
      --font-headline: ${fontSet.headline};
      --font-intro: ${fontSet.intro};
      --font-subheader: ${fontSet.subheader};
      --font-body: ${fontSet.body};
    `;
  }, [localisedFonts, language]);

  return <div css={fontStyles}>{children}</div>;
};
