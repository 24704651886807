import React from 'react';

interface ScreenReaderAnnouncementProps {
  'aria-live'?: 'off' | 'assertive' | 'polite';
  children: React.ReactNode;
}

export const ScreenReaderAnnouncement = (
  props: ScreenReaderAnnouncementProps
) => {
  return (
    <div
      aria-live={props['aria-live'] || 'polite'}
      role="alert"
      css={{ position: 'absolute', insetInlineEnd: '22222px' }}
    >
      {props.children}
    </div>
  );
};

export default ScreenReaderAnnouncement;
