import { EnnismoreTheme } from '@/ui/theme/ennismore-theme.interface';

const fonts = {
  headline: 'BwBetoGrandeLight, sans-serif',
  intro: 'BwBetoGrandeLight, sans-serif',
  subheader: 'BwBetoGrandeLight, sans-serif',
  body: 'Founders Grotesk, sans-serif',
};

export const soTheme: EnnismoreTheme = {
  themeKey: 'so',
  logoUrl: '/brands/so/images/logo.svg',
  faviconUrl: '/brands/so/favicon.ico',
  space: ['0px', '8px', '16px', '20px', '24px', '32px', '40px', '56px'],
  fonts,
  text: {
    heading1: {
      fontFamily: fonts.headline,
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 300,
      textTransform: 'uppercase',
      letterSpacing: '-0.96px',
    },
    heading2: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '64px',
      fontWeight: 400,
    },
    heading3: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '48px',
    },
    heading4: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '40px',
    },
    heading5: {
      fontFamily: fonts.subheader,
      fontSize: '20px',
      lineHeight: '35px',
      fontWeight: 300,
    },
    heading6: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '24px',
    },
    intro1: {
      fontFamily: fonts.intro,
      fontSize: '40px',
      lineHeight: '56px',
    },
    intro2: {
      fontFamily: fonts.intro,
      fontSize: '24px',
      lineHeight: '40px',
    },
    subheader1: {
      fontFamily: fonts.subheader,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.48px',
    },
    subheader2: {
      fontFamily: fonts.subheader,
      fontSize: '18px',
      lineHeight: '32px',
    },
    subheader3: {
      fontFamily: fonts.subheader,
      fontSize: '14px',
      lineHeight: '24px',
    },
    bodyLarge: {
      fontFamily: fonts.body,
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.48px',
    },
    bodyCopy: {
      fontFamily: fonts.body,
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.36px',
    },
    bodySmall: {
      fontFamily: fonts.body,
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 300,
      letterSpacing: '0.28px',
    },
    bodyExtraSmall: {
      fontFamily: fonts.body,
      fontSize: '11px',
      lineHeight: '22px',
    },
    captions: {
      fontFamily: fonts.body,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  colors: {
    white: 'white',
    textPrimary: '#17234E',
    textSecondary: '#999999',
    background: '#F7EDF4',
    primary100: '#f7f7e1',
    primary200: 'white',
    primary300: '#9B9B9B',
    primary400: '#e8e8e8',
    primary500: '#9B9B9B',
    secondary100: '#f6f6f6',
    secondary200: '#d9d9d9',
    secondary300: '#9B9B9B',
    secondary400: '#9B9B9B',
    error: '#B00020',
  },
  buttons: {
    primary: {
      color: '#F7EDF4',
      backgroundColor: '#23254E',
      fontFamily: fonts.body,
      fontSize: '16px',
      borderRadius: 100,
      fontWeight: 300,
      letterSpacing: '0.64px',
      transition: 'opacity .3s ease-out',
      lineHeight: '16px',
      width: 'auto',
      minWidth: '186px',
      padding: '24px 24px 16px 24px',
      ':hover': {
        opacity: 0.76,
      },
    },
    secondary: {
      color: '#23254E',
      backgroundColor: 'transparent',
      fontFamily: fonts.body,
      fontSize: '16px',
      borderRadius: 100,
      fontWeight: 300,
      letterSpacing: '0.64px',
      transition: 'opacity .3s ease-out',
      lineHeight: '16px',
      padding: '24px 24px 16px 24px',
      border: '1px solid #23254E3D',
      ':hover': {
        opacity: 0.76,
      },
    },
  },
  keylines: {
    border: '1px solid #DCDBDC',
  },
  forms: {
    checkoutForm: {
      paymentDisclaimer: {
        fontSize: '16px',
      },
      paymentButton: {
        width: '100%',
      },
      billingInformationDescription: {
        fontSize: '16px',
      },
      billingInformationHeading: {
        paddingTop: '26px',
      },
    },
    heading: {
      color: '#17234E',
      fontSize: '24px',
      lineHeight: '32px',
      textTransform: 'uppercase',
      letterSpacing: '0.72px',
      mobile: {
        fontSize: '16px',
      },
    },
    description: {
      fontSize: '16px',
    },
    input: {
      fontSize: '16px',
      backgroundColor: 'white',
      fontWeight: 400,
      letterSpacing: '0.36px',
      borderRadius: '4px',
      border: '1px solid #949494',
      disabled: {
        background: '#ECD7E5',
      },
    },
    label: {
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0.36px',
    },
    radioButton: {
      padding: '25px 0',
      description: {
        color: '#17234E',
        fontWeight: 400,
        letterSpacing: '0.32px',
        lineHeight: '24px',
        fontSize: '16px',
        padding: '2px 0 0 0',
      },
      borderBottom: '1px solid #DCDBDC',
      collapsibleContent: {
        padding: '8px 0 0 0',
      },
      selected: {
        padding: '25px 0 32px',
      },
    },
    radioGroup: {
      divider: {
        border: '1px solid #DCDBDC',
      },
    },
  },
  fontFaceDefinitions: [
    {
      fontFamily: 'BwBetoGrandeLight',
      fontWeight: 300,
      fontStyle: 'italic',
      sources: {
        woff2: '/brands/so/fonts/BwBetoGrande-LightItalic.woff2',
      },
    },
    {
      fontFamily: 'BwBetoGrandeLight',
      fontWeight: 300,
      sources: {
        woff2: '/brands/so/fonts/BwBetoGrande-Light.woff2',
      },
    },
    {
      fontFamily: 'Founders Grotesk',
      fontWeight: 300,
      sources: {
        woff2: '/brands/so/fonts/Founders-Grotesk-Light.woff2',
      },
    },
    {
      fontFamily: 'Founders Grotesk',
      fontWeight: 400,
      sources: {
        woff2: '/brands/so/fonts/Founders-Grotesk-Regular.woff2',
      },
    },
    {
      fontFamily: 'Founders Grotesk',
      fontWeight: '500 1000',
      sources: {
        woff2: '/brands/so/fonts/Founders-Grotesk-Medium.woff2',
      },
    },
  ],
  componentProperties: {
    confirmation: {
      mobile: {
        rowGap: '20px',
      },
      title: {
        fontSize: '32px',
        textTransform: 'uppercase',
        lineHeight: '42px',
        letterSpacing: '0.72px',
        fontFamily: fonts.headline,
        color: 'white',
        mobile: {
          fontSize: '26px',
          padding: '0 60px',
        },
      },
      confirmationMessage: {
        color: 'white',
        fontSize: '18px',
        mobile: {
          fontSize: '16px',
          padding: '30px 28px 40px',
        },
      },
      header: {
        backgroundColor: '#003727',
        paddingBottom: '32px',
        mobile: {
          paddingBottom: '0px',
        },
      },
      bookingConfirmationDetailsCard: {
        customerNameHeading: {
          fontFamily: fonts.headline,
          fontSize: '16px',
          fontWeight: 300,
        },
        bookingNumberHeading: {
          fontSize: '24px',
          fontWeight: 300,
          paddingBottom: '16px',
          mobile: {
            fontSize: '18px',
          },
        },
        addressHeading: {
          fontSize: '16px',
          lineHeight: '26px',
          letterSpacing: '0.32px',
          fontFamily: fonts.body,
        },
        totalDataRow: {
          label: {
            fontWeight: 300,
          },
          value: {
            fontSize: '16px',
            fontWeight: 500,
          },
        },
        manageMyBooking: {
          title: {
            fontSize: '18px',
            fontFamily: fonts.body,
            paddingBottom: '8px',
          },
          description: {
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '22px',
            letterSpacing: '0.14px',
            paddingBottom: '8px',
          },
          link: {
            fontSize: '16px',
            textTransform: 'uppercase',
            textDecoration: 'underline',
            letterSpacing: '0.8px',
            fontFamily: fonts.body,
            color: '#17234E',
          },
        },
        paymentCancellationPolicy: {
          title: {
            fontFamily: fonts.body,
            fontSize: '18px',
            lineHeight: '30px',
          },
          description: {
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '22px',
            letterSpacing: '0.14px',
            color: '#17234E',
          },
        },
        pricePerNightTitle: {
          fontSize: '16px',
          fontWeight: 500,
        },
        termsAndConditions: {
          fontFamily: fonts.body,
          fontSize: '14px',
          lineHeight: '22px',
          letterSpacing: '0.14px',
          fontWeight: 300,
        },
      },
      bookingConfirmationAdditionalDetailsContainer: {
        rowGap: '35px',
        padding: '32px 32px 35px 32px',
        mobile: {
          padding: '35px 16px',
        },
      },
      bookingConfirmationContainer: {
        margin: '60px auto auto auto',
        mobile: {
          margin: '40px 16px 0',
        },
        divider: {
          borderColor: '#DCDBDC',
        },
      },
      headerImage: {
        height: '480px',
        objectFit: 'cover',
        container: {
          width: '100%',
          paddingBottom: '60px',
          margin: '0',
          mobile: {
            paddingBottom: '30px',
          },
        },
        mobile: {
          height: '220px',
        },
      },
      anyQuestionsCard: {
        mobile: {
          margin: '0 16px',
          padding: '24px 16px 35px',
        },
        title: {
          textTransform: 'uppercase',
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '0.72px',
          mobile: {
            fontSize: '16px',
            letterSpacing: '-0.64px',
            lineHeight: '24px',
          },
        },
        description: {
          fontSize: '16px',
        },
      },
      thirdPartyPaymentDownloadCard: {
        description: {
          fontSize: '16px',
        },
      },
      restaurantsCard: {
        mobile: {
          margin: '0 16px',
          padding: '24px 16px 35px',
        },
        title: {
          textTransform: 'uppercase',
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '0.72px',
          mobile: {
            fontSize: '16px',
            letterSpacing: '-0.64px',
            lineHeight: '24px',
          },
        },
        restaurantTitle: {
          fontSize: '20px',
        },
        restaurantDescription: {
          fontSize: '16px',
        },
      },
      whereToFindUsCard: {
        mobile: {
          margin: '0 16px',
          padding: '24px 16px 35px',
        },
        title: {
          textTransform: 'uppercase',
          fontSize: '24px',
          lineHeight: '32px',
          letterSpacing: '0.72px',
          mobile: {
            fontSize: '16px',
            letterSpacing: '-0.64px',
            lineHeight: '24px',
          },
        },
        planYourJourneyLink: {
          color: '#1A1A1A',
        },
      },
    },
    consentInputs: {
      title: {
        display: 'none',
      },
    },
    bookingManagement: {
      pageWrapper: {
        login: { alignContent: 'center' },
        verify: { alignContent: 'center' },
      },
      title: {
        textTransform: 'uppercase',
        fontSize: '32px',
        lineHeight: '40px',
        letterSpacing: '-0.96px',
        fontFamily: fonts.headline,
        fontWeight: 300,
        paddingBottom: '30px',
        mobile: {
          fontSize: '26px',
          lineHeight: '40px',
          letterSpacing: '-0.78px',
          paddingBottom: '4px',
        },
      },
      subTitle: {
        fontSize: '24px',
        lineHeight: '32px',
        letterSpacing: '0.72px',
        textTransform: 'uppercase',
        paddingBottom: '8px',
        mobile: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.64px',
        },
      },
      description: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.32px',
        fontWeight: 400,
      },
      button: {
        width: 'min(100%, 416px)',
      },
      input: {
        width: 'min(100%, 416px)',
      },
      bookingCard: {
        border: 'none',
        borderRadius: '0',
        body: {
          rowGap: '32px',
          padding: '32px 24px',
        },
        header: {
          borderRadius: '0',
          backgroundColor: 'white',
          borderBottom: '1px solid #DCDBDC',
          fontFamily: fonts.body,
          textTransform: 'none',
          fontSize: '16px',
          lineHeight: '26px',
          letterSpacing: '0.32px',
          height: '88px',
          cancelled: {
            backgroundColor: '#17234E',
            color: 'white',
            pillowBorder: 'none',
          },
        },
        footer: {
          borderTop: '1px solid #DCDBDC',
        },
        button: {
          variant: 'secondary',
        },
        cancellationModal: {
          maxWidth: '772px',
          padding: '80px 124px',
          textAlign: 'center',
          borderRadius: '0',
          title: {
            textTransform: 'uppercase',
            fontSize: '32px',
            letterSpacing: '-0.96px',
            fontWeight: 300,
            paddingBottom: '40px',
          },
          body: {
            maxWidth: '50ch',
            mobile: {
              maxWidth: '100%',
            },
          },
          confirmButton: {
            width: '100%',
          },
          mobile: {
            padding: '40px 16px',
            title: {
              fontSize: '26px',
            },
          },
        },
      },
      bookingCards: {
        mobile: {
          paddingBottom: '60px',
        },
      },
    },
    header: {
      height: 90,
      borderBottom: '1px solid #DCDBDC',
      logo: {
        borderRight: 'none',
      },
      backgroundColor: '#F7EDF4',
      wizard: {
        step: {
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: 400,
          opacity: 0.56,
          fontFamily: fonts.body,
          letterSpacing: '0.24px',
          active: {
            opacity: 1,
            fontWeight: 500,
          },
        },
      },
      languageAndCurrencySelector: {
        dropdown: {
          display: 'none',
        },
        mobile: {
          fontSize: '14px',
        },
      },
    },
    footer: {
      backgroundColor: '#F7EDF4',
      color: '#17234E',
      fontFamily: fonts.body,
      letterSpacing: '0.36px',
      fontWeight: 400,
      lineHeight: '26px',
      fontSize: '16px',
      borderTop: '1px solid #DCDBDC',
      copyright: {
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.28px',
      },
      mobile: {
        marginTop: '0px',
        fontSize: '16px',
      },
    },
    requestsPage: {
      paymentMethods: {
        mobile: {
          paddingBottom: '40px',
        },
      },
    },
    search: {
      title: {
        textTransform: 'uppercase',
      },
      results: {
        mobile: {
          paddingBottom: '0px',
        },
        roomImageDisclaimer: {
          mobile: {
            paddingBottom: '24px',
          },
        },
        bedroomAvailabilityList: {
          rowGap: '40px',
          mobile: {
            rowGap: '20px',
          },
        },
        roomSpecColumns: 2,
        sidebarContainer: {
          cancellationPolicy: {
            backgroundColor: 'transparent',
            border: '1px solid #D4B6C7',
            borderRadius: '6px',
            color: '#17234E',
            fontFamily: fonts.body,
            lineHeight: '22px',
            fontWeight: 300,
            title: {
              lineHeight: '30px',
              fontWeight: 400,
            },
            mobile: {
              textTransform: 'uppercase',
              color: '#17234E',
              fontFamily: fonts.body,
              letterSpacing: '0.7px',
              fontSize: '14px',
              lineHeight: '26px',
              opacity: 0.6,
            },
            readMoreButton: {
              fontWeight: 300,
            },
          },
          hotelPerks: {
            border: 'none',
            rowGap: '24px',
            mobile: {
              backgroundColor: '#D4B6C7',
              title: {
                fontSize: '16px',
                textTransform: 'uppercase',
              },
              button: {
                textTransform: 'uppercase',
                fontSize: '14px',
                letterSpacing: '0.7px',
                lineHeight: '26px',
                opacity: 0.6,
              },
            },
          },
        },
      },
      collapsibleForm: {
        searchSummary: {
          border: 'none',
          lineHeight: '35px',
          borderRadius: '6px',
          mobile: {
            padding: '16px',
            title: {
              fontSize: '16px',
              textTransform: 'uppercase',
            },
          },
          modifySearchButton: {
            textTransform: 'uppercase',
            fontSize: '14px',
            letterSpacing: '0.7px',
            lineHeight: '26px',
            fontFamily: fonts.body,
            opacity: 0.6,
            mobile: {
              fontSize: '12px',
              lineHeight: '12px',
              letterSpacing: '1.44px',
            },
          },
          expanded: { borderRadius: '6px 6px 0 0' },
        },
        searchForm: {
          borderRadius: '0 0 6px 6px',
        },
      },
    },
    modals: {
      fontSize: '16px',
      title: {
        textTransform: 'uppercase',
        fontFamily: fonts.headline,
        fontSize: '32px',
        fontWeight: 300,
        lineHeight: '44x',
        mobile: {
          fontSize: '28px',
          lineHeight: '34px',
          letterSpacing: '0.56px',
        },
      },
    },
    calendar: {
      border: '1px solid #DCDBDC',
      borderRadius: '4px',
      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
      color: '#17234E',
      header: {
        color: '#17234E',
        backgroundColor: '#F7EDF4',
        borderRadius: '4px 4px 0 0',
        arrow: {
          stroke: '#DCDBDC',
          top: '-1px',
        },
        caption: {
          fontWeight: 400,
          fontFamily: fonts.body,
          textTransform: 'uppercase',
          color: '#17234E',
          letterSpacing: '0.9px',
          fontSize: '16px',
        },
        nav: {
          hover: {
            backgroundColor: 'hsl(204deg 83% 9% / 10%)',
          },
        },
      },
      body: {
        date: {
          hover: {
            backgroundColor: 'hsl(227deg 54% 20% / 30%)',
          },
          selected: { backgroundColor: '#17234E' },
          selectedMiddle: { backgroundColor: '#E0E7F2' },
        },
      },
    },
    bedroomCard: {
      roomTitle: {
        fontSize: '24px',
        fontWeight: 300,
        letterSpacing: '0.72px',
        textTransform: 'uppercase',
        lineHeight: '32px',
        mobile: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '-0.64px',
          fontWeight: 300,
        },
      },
      roomRateName: {
        fontSize: '18px',
        letterSpacing: '0.9px',
        fontFamily: fonts.body,
        textTransform: 'uppercase',
        marginTop: '-2px',
        paddingBottom: '10px',
      },
      description: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        letterSpacing: '0.32px',
      },
      totalPrice: {
        fontFamily: fonts.body,
        lineHeight: '24px',
      },
      totalLabel: {
        fontSize: '14px',
        letterSpacing: '0.28px',
        fontWeight: 400,
      },
      perNightPriceLabel: {
        fontSize: '14px',
        letterSpacing: '0.28px',
      },
      roomSpecItem: {
        letterSpacing: '0.28px',
        color: '#9B9B9B',
        value: {
          color: '#17234E',
          fontWeight: 400,
        },
      },
      button: {
        width: '100%',
      },
    },
    sidebarCards: {
      secondary: {
        borderRadius: '0px',
        padding: 'm',
        backgroundColor: 'background',
        borderColor: 'primary300',
        color: 'textPrimary',
      },
      primary: {
        borderRadius: '0px',
        padding: 'l',
        backgroundColor: 'primary200',
        borderColor: 'white',
        color: 'textPrimary',
      },
    },
    checkoutSidebar: {
      cancellationPolicy: {
        backgroundColor: 'transparent',
        border: '1px solid #D4B6C7',
        title: {
          lineHeight: '30px',
          fontSize: '18px',
          fontFamily: fonts.body,
        },
        description: {
          color: '#17234E',
        },
        borderRadius: '6px',
      },
    },
    staySummary: {
      border: 'none',
      title: {
        fontSize: '24px',
        lineHeight: '34px',
        textTransform: 'uppercase',
        mobile: {
          fontSize: '16px',
          lineHeight: '0px',
        },
      },
      summarySection: {
        padding: '35px 24px',
        mobile: {
          padding: '35px 16px',
        },
      },
      roomNumberHeading: {
        fontSize: '18px',
        fontFamily: fonts.body,
        lineHeight: '26px',
        letterSpacing: '1.8px',
        textTransform: 'uppercase',
        padding: '0 0 16px 0',
      },
      dataRow: {
        label: {
          opacity: 1,
          fontWeight: 300,
          fontSize: '16px',
        },
        value: { fontWeight: 400, fontSize: '16px' },
      },
      total: {
        value: {
          fontWeight: 500,
        },
      },
      dropdown: {
        textTransform: 'uppercase',
        opacity: 0.6,
        fontSize: '14px',
        letterSpacing: '0.28px',
      },
      divider: {
        borderColor: '#DCDBDC',
      },
    },
    imageSlider: {
      paginator: {
        color: '#17234E',
        line: {
          margin: '10px 10px 0 10px',
        },
      },
    },
    cards: {
      primary: {
        border: 'none',
        divider: {
          borderColor: '#DCDBDC',
        },
      },
    },
    banners: {
      backgroundColor: 'primary200',
      color: 'textPrimary',
    },
    page: {
      stepLabel: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0.28px',
      },
      background: '#F7EDF4',
      title: {
        mobile: {
          fontSize: '26px',
          lineHeight: '40px',
          letterSpacing: '-0.78px',
          fontWeight: 300,
          textTransform: 'uppercase',
          fontFamily: fonts.headline,
        },
      },
      screenLayout: {
        mobile: {
          paddingBottom: '0px',
        },
      },
    },
    hotelPerk: {
      gap: '8px',
      title: {
        fontFamily: fonts.body,
        fontWeight: 400,
        lineHeight: '22px',
      },
      description: {
        opacity: 1,
        lineHeight: '16px',
      },
    },
    addOnCard: {
      disclaimer: {
        borderRadius: '0px',
        warning: {
          fontSize: '18px',
          lineHeight: '30px',
          letterSpacing: '0.32px',
          fontFamily: fonts.body,
          fontWeight: 500,
        },
        rateName: {
          fontSize: '16px',
          lineHeight: '26px',
          letterSpacing: '0.32px',
          fontFamily: fonts.body,
          fontWeight: 500,
        },
        rateDescription: {
          fontSize: '16px',
          lineHeight: '26px',
          letterSpacing: '0.32px',
          fontFamily: fonts.body,
          fontWeight: 400,
        },
      },
      border: 'none',
      title: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '-0.64px',
        textTransform: 'uppercase',
        fontWeight: 300,
      },
      subTitle: {
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 400,
      },
      description: {
        fontSize: '16px',
        lineHeight: '26px',
        fontWeight: 400,
        opacity: 0.72,
        color: 'black',
      },
      ctaLabel: {
        fontWeight: 400,
        color: '#2F2F2F',
      },
    },
    halfScreenLayout: {
      borderRight: 'none',
    },
    paymentMethods: {
      googlePay: {
        borderRadius: '100px',
      },
      applePay: {
        borderRadius: '100px',
      },
    },
  },
  icons: {},
  __temporary: {
    displayCarTransportOnNewLine: true,
  },
};
