import { CountryCountry, ProfileTitle } from '@/em-api-client-typescript-fetch';

import type { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const gleneaglesBrandConfig: BrandConfigurationFactory = () => ({
  chainCode: 'gleneagles',
  themeKey: 'gleneagles',
  name: 'Gleneagles',
  hotels: [
    {
      referenceId: 'gleneagles',
      code: 'GLEN',
      name: 'Gleneagles',
      dialect: 'ows',
      visibility: {
        search: true,
      },
    },
  ],
  homeUrl: 'https://gleneagles.com',
  domains: [
    'booking.gleneagles.local',
    'gleneagles.dev.env.ennismore.com',
    'booking.gleneagles.com',
    'em-booking-ui-gleneagles-mt.vercel.app',
    'vercel:em-booking-ui-gleneagles-mt',
    'gleneagles.staging.env.ennismore.com',
    'gleneagles-sst.dev.env.ennismore.com',
    'gleneagles-sst.staging.env.ennismore.com',
    'gleneagles-sst.live.env.ennismore.com',
  ],
  hideUnavailableRoomRates: true,
  hideAccessibleRoomsCheckbox: true,
  maxRoomCountPerBooking: 4,
  supportedRoomOccupancyConfigurations: [
    { adults: 1, children: 0 },
    { adults: 2, children: 0 },
    { adults: 3, children: 0 },
    { adults: 4, children: 0 },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://gleneagles.com/gleneagles/privacy-policy/',
        cookiePolicy: 'https://gleneagles.com/terms-conditions/',
        termsOfUse: 'https://gleneagles.com/terms-conditions/',
        contact: 'mailto:reservations@gleneagles.com',
      },
    },
  },
  profileTitleOptions: [
    ProfileTitle.MRS,
    ProfileTitle.MISS,
    ProfileTitle.MR,
    ProfileTitle.MRMRS,
    ProfileTitle.MS,
    ProfileTitle.DR,
    ProfileTitle.DRMR,
    ProfileTitle.DRMRS,
    ProfileTitle.LORD,
    ProfileTitle.LADY,
    ProfileTitle.SHEIKH,
    ProfileTitle.SIR,
    ProfileTitle.CAPTAIN,
    ProfileTitle.PROFESSOR,
    ProfileTitle.MX,
  ],
  showRoomTotalInBreakdown: false,
  showTaxTotalInBreakdown: false,
  showInlineCalendar: false,
  translationOverrides: {
    'en-GB': enGB,
  },
  highlightedCountryCodes: [CountryCountry.GB],
  marketingConsentMode: 'opt-out',
  features: {
    bookingManagement: false,
  },
  analytics: {
    gtm: {
      containerId: 'GTM-TKTTLK3',
    },
  },
});
