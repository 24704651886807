import React from 'react';

import { useTheme } from '../theme';

interface FullScreenLayoutProps {
  children: React.ReactNode;
}

/**
 * Expand to fit the full height of the viewport, less the height of the sticky Header (need to centralise that).
 * This is usually to keep the footer below the fold for shorter pages.
 * @param props children
 */
export const FullScreenLayout = (props: FullScreenLayoutProps) => {
  const {
    componentProperties: { page },
  } = useTheme();
  return (
    <div
      css={{
        minHeight: 'calc(100vh - 61px)',
        paddingBottom: '42px',
        '@media all and (max-width: 730px)': page?.screenLayout?.mobile,
      }}
    >
      {props.children}
    </div>
  );
};
