import {
  V1GetCalendarRequest,
  V1GetCalendarResponse,
} from '@ennismore/booking-api-client';
import { UseQueryResult, useQuery } from 'react-query';

import { useEnnismoreApiClientConfig } from '@/api';
import {
  selectHotelConfigurationByReferenceIdOrFail,
  useActiveBrandConfig,
} from '@/brand';
import { useExperimentStatus } from '@/flags/use-experiments';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { useLocale } from '@/i18n';

import { getBookingServiceClient } from '../services/client';

/**
 * Fetches a calendar availability matching the given request
 * @param request Arguments required to identify the booking
 * @returns Query result containing the calendar availability
 */
export const useCalendarAvailability = (
  request: V1GetCalendarRequest
): UseQueryResult<V1GetCalendarResponse> => {
  const showCalendarAvailability = useExperimentStatus('calendar-availability');

  const brandConfig = useActiveBrandConfig();
  const locale = useLocale();
  const clientConfig = useEnnismoreApiClientConfig();

  const hotelReferenceId = resolveHotelReferenceIdFromHotelCode(
    request.hotelReferenceId
  );

  const fetchCalendarAvailability = async () => {
    const { dialect } = selectHotelConfigurationByReferenceIdOrFail(
      brandConfig,
      hotelReferenceId
    );
    const client = getBookingServiceClient(clientConfig, dialect);

    return await client.getCalendar({
      ...request,
      hotelReferenceId,
      locale: { language: locale.baseName },
    });
  };

  return useQuery(['getCalendar', request], fetchCalendarAvailability, {
    enabled: showCalendarAvailability,
    staleTime: 5 * 60 * 1000, // Keep data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Cache the data for 30 minutes
    refetchOnWindowFocus: false, // Prevent refetch on window focus
  });
};
