import { observer } from 'mobx-react-lite';
import React from 'react';

import { useActiveBrandConfig } from '@/brand';
import { useTheme } from '@/ui/theme';

export const BrandLogo = observer(() => {
  const {
    logoUrl,
    componentProperties: { header },
  } = useTheme();
  const { name } = useActiveBrandConfig();
  return (
    <img
      css={{
        padding: header?.logo?.padding,
        paddingBlock: header?.logo?.paddingBlock,
        paddingInline: header?.logo?.paddingInline,
      }}
      src={logoUrl}
      alt={name}
    />
  );
});

export default BrandLogo;
