import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const hydeBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'hyde',
  themeKey: 'hyde',
  name: 'Hyde',
  // defaultHotelCode: 'hyde.ibiza-ibiza',
  hotels: [
    {
      referenceId: 'hyde.ibiza-ibiza',
      code: 'hyde.ibiza-ibiza',
      name: 'Hyde Ibiza',
      dialect: 'ohip',
      openingDate: '2025-04-27',
    },
    {
      referenceId: 'hyde.londoncity-london',
      code: 'hyde.londoncity-london',
      name: 'Hyde London City',
      dialect: 'ohip',
      openingDate: '2024-08-15',
    },
    {
      referenceId: 'hyde.dubai-dubai',
      code: 'hyde.dubai-dubai',
      name: 'Hyde Dubai',
      dialect: 'ohip',
    },
    {
      referenceId: 'hyde.midtown-miami',
      code: 'hyde.midtown-miami',
      name: 'Hyde Midtown Miami',
      dialect: 'ohip',
      visibility: {
        search: false,
      },
    },
    {
      referenceId: 'hyde.bodrum-bodrum',
      code: 'hyde.bodrum-bodrum',
      name: 'Hyde Bodrum',
      dialect: 'ohip',
      visibility: {
        search: false,
      },
    },
  ],
  homeUrl: 'https://hydehotels.com',
  domains: [
    'booking.hyde.local',
    'hyde.dev.env.ennismore.com',
    'hyde.staging.env.ennismore.com',
    'booking.hydehotels.com',
    'hyde-sst.dev.env.ennismore.com',
    'hyde-sst.staging.env.ennismore.com',
    'hyde-sst.live.env.ennismore.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US'],
  },

  maxRoomCountPerBooking: 10,

  search: {
    errorResolutionType: 'contact-button',
  },

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 2,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 4,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://hydehotels.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://hydehotels.com/terms-and-policies/cookie-policy/',
        termsOfUse:
          'https://hydehotels.com/terms-and-policies/terms-and-conditions-of-services/',
        // TODO: Add link to contact us page
        contact: '/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: false,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
  },

  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-5SRF3D8Q' : 'GTM-MPHW5DP2',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '385a4ce6-708b-4efb-bdc6-24a6ead72dfc',
    },
  },
  maxChildAge: 11,
});
