import { Global, css } from '@emotion/react';
import { DirectionProvider } from '@radix-ui/react-direction';
import i18n from 'i18next';
import Locale from 'intl-locale-textinfo-polyfill';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { I18nextProvider } from 'react-i18next';

import { useActiveBrandConfig } from '@/brand';
import { formatUTCDate, setDayjsLocale } from '@/common/utils/date';
import { useFeatureSwitchStatus } from '@/features';

import { getTranslationStrings } from './languages';

interface LocaleProviderProps {
  children: React.ReactNode;
}

type Dir = 'rtl' | 'ltr';

let currentLanguage = 'en-GB';

/**
 * Language getter. To be used in cases where language is needed outside of React components.
 */
export const getCurrentLanguage = () => currentLanguage;

const setCurrentLanguage = (language: Intl.UnicodeBCP47LocaleIdentifier) => {
  currentLanguage = language;
};

/**
 * Updates the language within i18next and content direction using the locale from the Next.js router.
 * @param props
 */
export const LocaleProvider: React.FC<LocaleProviderProps> = (props) => {
  const router = useRouter();
  const brandConfig = useActiveBrandConfig();

  const [instance] = useState(() => i18n.createInstance());

  const [direction, setDirection] = useState<Dir>('ltr');

  const rtl = useFeatureSwitchStatus('rtl');

  useEffect(() => {
    const dir = rtl
      ? 'rtl'
      : router.locale
      ? // Using a ponyfill here until Firefox supports the Intl.Locale.prototype.getTextInfo API
        (new Locale(router.locale).textInfo.direction as Dir)
      : 'ltr';

    document.documentElement.dir = dir;

    setDirection(dir);
  }, [rtl, router.locale]);

  useMemo(() => {
    void instance.init({
      resources: getTranslationStrings(brandConfig.translationOverrides),
      lng: 'en-GB', // Default language - could be defined in brand config in future
      fallbackLng: 'en-GB',
      saveMissing: true,

      interpolation: {
        escapeValue: false,

        format: (value, format) => {
          if (value instanceof Date && format) {
            return formatUTCDate(value, format);
          }
          return value;
        },
      },
    });
  }, [brandConfig.chainCode]);

  useEffect(() => {
    const locale = router.locale
      ? new Intl.Locale(router.locale).baseName
      : 'en-GB';
    void instance?.changeLanguage(locale);
    setDayjsLocale(locale);
    setCurrentLanguage(locale);
  }, [router.locale, instance]);

  return (
    <I18nextProvider i18n={instance}>
      <DirectionProvider dir={direction}>
        <Global
          styles={css`
            :root {
              --content-direction-factor: 1;
            }
            [dir='rtl'] {
              --content-direction-factor: -1;
            }
          `}
        />
        {props.children}
      </DirectionProvider>
    </I18nextProvider>
  );
};

export default LocaleProvider;
