import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SearchFormFields } from '@/availability/validators/search-form.validator';
import { Box } from '@/box';
import {
  getAccessibleDateString,
  getLocalisedDateString,
} from '@/common/utils/date';
import { useLocale, useTranslation } from '@/i18n';
import { Input } from '@/ui/form';
import { useTheme } from '@/ui/theme';

export const StartDateField = ({
  startDateId,
  value,
  onFocusHandler,
  onClickHandler,
  triggerRef,
  singleDate,
}: {
  startDateId: string;
  value?: string;
  onFocusHandler?: React.FocusEventHandler;
  onClickHandler?: React.MouseEventHandler;
  triggerRef: React.MutableRefObject<HTMLButtonElement>;
  singleDate?: boolean;
}) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext<Pick<SearchFormFields, 'from'>>();
  /**
   * Used to conditionally add & remove Input from the tab order
   */
  const [inputTabIndex, setInputTabIndex] = useState(0);
  const locale = useLocale();

  const { forms } = useTheme();

  // Revalidates the check-in date whenever the value changes.
  // Doing this within the onChange event handler doesn't work as we're controlling the value.
  useEffect(() => {
    trigger('from');
  }, [value]);

  return (
    <Box
      width={[singleDate ? 1 : 'calc(50% - 7.5px)', 'calc(50% - 7.5px)']}
      marginInlineEnd={singleDate ? 0 : 15}
      position="relative"
      {...forms?.searchForm?.startDateFieldContainer}
    >
      <Input
        type="text"
        name={startDateId}
        readOnly
        hideError
        value={value ? getLocalisedDateString(value, locale) : ''}
        placeholder={
          singleDate ? t('search:form.date') : t('search:form.startDate')
        }
        onFocus={(e) => {
          // Remove the input from the tab order, so that backward tabbing works while on the button.
          setInputTabIndex(-1);
          triggerRef.current.focus();
          onFocusHandler?.(e);
        }}
        onClick={onClickHandler}
        tabIndex={inputTabIndex}
      >
        <button
          ref={triggerRef}
          css={css`
            position: absolute;
            height: 100%;
            width: 100%;
            &:focus {
              /* force applied as a temp solution while 'focus-visible' module is in place */
              outline: 1px solid #8f8f8f !important;
              outline-offset: 2px;
              border-radius: ${forms.input?.borderRadius ?? '2px'};
            }
          `}
          onFocus={(e) => {
            setInputTabIndex(-1);
            onFocusHandler?.(e);
          }}
          onClick={onClickHandler}
          onBlur={() => setInputTabIndex(0)} // Restore input to the tab order
          aria-label={`${t('search:form.checkInLabel')} - ${
            value ? getAccessibleDateString(value, locale) : 'not selected'
          }`}
        />
      </Input>
    </Box>
  );
};
