import React from 'react';

import { useTranslation } from '@/i18n';
import { useTheme } from '@/ui/theme';
import { BodyCopy } from '@/ui/typography';

interface StepProgressProps {
  total: number;
  current: number;
}

export const StepProgress = (props: StepProgressProps) => {
  const { t } = useTranslation();
  const {
    componentProperties: { page },
  } = useTheme();
  return (
    <BodyCopy css={page?.stepLabel}>
      {t('common:controls.steps', {
        currentStep: props.current,
        totalStepCount: props.total,
      })}
    </BodyCopy>
  );
};

export default StepProgress;
