import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';
import es from './locale/es.json';
import fr from './locale/fr.json';

export const soBrandConfig: BrandConfigurationFactory = ({ isProduction }) => ({
  chainCode: 'so',
  themeKey: 'so',
  name: 'SO/',
  defaultHotelCode: 'so.paris-paris',
  hotels: [
    {
      referenceId: 'so.auckland-auckland',
      code: 'so.auckland-auckland',
      name: 'Auckland, New Zealand',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.berlindasstue-berlin',
      code: 'so.berlindasstue-berlin',
      name: 'Berlin, Germany',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.paris-paris',
      code: 'so.paris-paris',
      name: 'Paris, France',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.sotogrande-malaga',
      code: 'so.sotogrande-malaga',
      name: 'Sotogrande, Spain',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.uptown-dubai',
      code: 'so.uptown-dubai',
      name: 'Dubai, UAE',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.vienna-vienna',
      code: 'so.vienna-vienna',
      name: 'Vienna, Austria',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.bangkok-bangkok',
      code: 'so.bangkok-bangkok',
      name: 'Bangkok, Thailand',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.sofitelhuahin-bangkao',
      code: 'so.sofitelhuahin-bangkao',
      name: 'Hua Hin, Thailand',
      dialect: 'ohip',
    },
    {
      referenceId: 'so.stpetersburg-saintpetersburg',
      code: 'so.stpetersburg-saintpetersburg',
      name: 'SO/ St. Petersburg',
      dialect: 'ohip',
      visibility: {
        search: false,
      },
    },
    {
      referenceId: 'so.maldives-male',
      code: 'so.maldives-male',
      name: 'Maldives',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://so-hotels.com',
  domains: [
    'booking.so.local',
    'so.dev.env.ennismore.com',
    'so.staging.env.ennismore.com',
    'booking.so-hotels.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'fr', 'es'],
  },

  maxRoomCountPerBooking: 7,

  search: {
    errorResolutionType: 'contact-button',
  },

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 2,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 4,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://so-hotels.com/en/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://so-hotels.com/en/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://so-hotels.com/en/terms-and-policies',
        contact: 'https://so-hotels.com/en/contact',
      },
      fr: {
        privacyPolicy:
          'https://so-hotels.com/en/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://so-hotels.com/en/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://so-hotels.com/en/terms-and-policies',
        contact: 'https://so-hotels.com/fr/contact/',
      },
      es: {
        privacyPolicy:
          'https://so-hotels.com/en/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://so-hotels.com/en/terms-and-policies/cookie-policy/',
        termsOfUse: 'https://so-hotels.com/en/terms-and-policies',
        contact: 'https://so-hotels.com/es/contacto/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  showAveragePricePerNight: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],

  features: {
    bookingManagement: true,
  },

  marketingEmailSignUpAtCheckout: false,
  collectPrivacyPolicyConsentAtCheckout: true,
  ohipDiscountCodeType: 'CORPORATE',

  translationOverrides: {
    'en-GB': enGB,
    fr: fr,
    es: es,
  },

  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-P78Q5M2' : undefined,
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: 'a138a481-4e05-4624-8095-04fe0c8e51c6',
    },
  },
});
