import { createContext } from 'react';

import type { AvailableThemeKey } from './available-theme-keys.type';

interface ThemeSwitcher {
  setActiveThemeKey: (themeKey: AvailableThemeKey) => void;
  resetThemeKey: () => void;
  activeThemeKey: AvailableThemeKey;
  defaultThemeKey: AvailableThemeKey;
}

export const ThemeSwitcherContext = createContext<ThemeSwitcher | null>(null);
