import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import React from 'react';

import type { AvailableThemeKey } from '.';
import FontProvider from '../typography/FontProvider.component';
import { EnnismoreThemeContext } from './ennismore-theme.context';
import { getTheme } from './getTheme.function';
import { withDefaultIcons } from './icons';

const getChakraTheme = () => {
  const config = {
    cssVarPrefix: 'em',
  };

  return {
    ...extendTheme({ config }),
    // Why are we not passing the breakpoints to config?
    // For some reason, extendTheme() doesn't pass them through...
    breakpoints: {
      base: '0em',
      sm: '40em',
      md: '48em',
      lg: '62em',
      xl: '80em',
      '2xl': '96em',
    },
  };
};

export const ThemeProvider = ({
  children,
  themeKey,
}: {
  children: React.ReactNode;
  themeKey?: AvailableThemeKey;
}) => {
  if (!themeKey) {
    throw new Error('`themeKey` prop is required');
  }

  const theme = getTheme(themeKey);
  const chakraTheme = getChakraTheme();

  return (
    <FontProvider fontFaceDefinitions={theme.fontFaceDefinitions}>
      <EnnismoreThemeContext.Provider value={withDefaultIcons(theme)}>
        <ChakraProvider theme={chakraTheme}>
          <Global
            styles={css`
              body {
                background: ${theme.colors.background};
              }
            `}
          />
          {children}
        </ChakraProvider>
      </EnnismoreThemeContext.Provider>
    </FontProvider>
  );
};
