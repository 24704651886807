import { css } from '@emotion/react';
import React from 'react';

import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

interface BasicSidebarSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const BasicSidebarSection = (props: BasicSidebarSectionProps) => {
  const { componentProperties } = useTheme();
  const styleOverride =
    componentProperties.checkoutSidebar?.cancellationPolicy?.title ?? {};
  return (
    <div className={props.className}>
      <Heading css={styleOverride} as="h3" style="heading6">
        {props.title}
      </Heading>

      <div
        css={css`
          max-width: 100%;
          margin-top: 8px;
        `}
      >
        {props.children}
      </div>
    </div>
  );
};

export default BasicSidebarSection;
