import { getCookies } from 'cookies-next';
import { GetServerSidePropsContext, NextApiRequest } from 'next';
import { AppContext } from 'next/app';
import { NextRequest } from 'next/server';

import { getPublicEnvironmentConfig } from '@/env/client/config';
import { getHostnameFromRequest } from '@/web/middleware/utils';

import { MissingBrandConfigError, MissingHostnameError } from '../../errors';
import { AvailableBrandConfigurations } from '../constants';
import { BRAND_OVERRIDE_COOKIE_KEY } from '../constants/cookie-keys.const';
import { formatHostname } from '../helpers';
import { BrandConfigurationSchema } from '../interfaces';

/**
 * Fetches the brand configuration that matches the given hostname.
 *
 * For now, this is just pulling the config locally; in future this method will fetch this data from the CMS.
 *
 * @param hostname e.g. google.com - the protocol and port will be stripped
 */
const fetchBrandConfig = async (hostname: string) => {
  for (const config of Object.values(AvailableBrandConfigurations)) {
    if (config.domains.includes(hostname)) {
      return BrandConfigurationSchema.parse(config);
    }
  }

  return undefined;
};

/**
 * Fetches the brand configuration that respects the overriden brand key set in cookie or one that matches the given hostname.
 *
 * For now, this is just pulling the config locally; in future this method will fetch this data from the CMS.
 */
export const fetchBrandConfigForServerSidePropsContext = async (
  context: Pick<GetServerSidePropsContext, 'req'>
) => {
  const overrideBrandKey = getCookies(context)[BRAND_OVERRIDE_COOKIE_KEY];

  const hostname = context.req.headers.host;

  if (!hostname) throw new MissingHostnameError();

  return resolveBrandConfig({ hostname, overrideBrandKey });
};

export const fetchBrandConfigForNextRequest = async (request: NextRequest) => {
  const hostname = getHostnameFromRequest(request);

  if (!hostname) {
    throw new MissingHostnameError();
  }

  const overrideBrandKey = request.cookies.get(
    BRAND_OVERRIDE_COOKIE_KEY
  )?.value;

  return resolveBrandConfig({ hostname, overrideBrandKey });
};

export const fetchBrandConfigForNextApiRequest = async (
  request: NextApiRequest
) => {
  const hostname = request.headers.host;

  if (!hostname) {
    throw new MissingHostnameError();
  }

  const overrideBrandKey = request.cookies[BRAND_OVERRIDE_COOKIE_KEY];

  return resolveBrandConfig({ hostname, overrideBrandKey });
};

export const fetchBrandConfigForNextInitialProps = async (
  context: AppContext
) => {
  const env = getPublicEnvironmentConfig();

  const hostname =
    env.NEXT_PUBLIC_OVERRIDE_HOSTNAME ??
    context.ctx.req?.headers.host ??
    window?.location.hostname;

  if (!hostname) {
    throw new MissingHostnameError();
  }

  const overrideBrandKey = getCookies(context.ctx)[BRAND_OVERRIDE_COOKIE_KEY];

  return resolveBrandConfig({ hostname, overrideBrandKey });
};

const resolveBrandConfig = async (args: {
  hostname: string;
  overrideBrandKey?: string;
}) => {
  const isVercel = typeof process.env.NEXT_PUBLIC_VERCEL_URL !== 'undefined';

  if (args.overrideBrandKey) {
    return await fetchBrandConfigByReference(args.overrideBrandKey);
  }

  const hostname = process.env.NEXT_PUBLIC_OVERRIDE_HOSTNAME ?? args.hostname;

  // Default to 'hoxton' for Vercel Preview Environments
  if (isVercel) {
    return await fetchBrandConfigByReference('hoxton');
  }

  return fetchBrandConfigOrFail(formatHostname(hostname));
};

const fetchBrandConfigByReference = async (brandReferenceId: string) => {
  return BrandConfigurationSchema.parse(
    AvailableBrandConfigurations[brandReferenceId]
  );
};

/**
 * Fetches the brand configuration that matches the given hostname.
 * Throws an error if the config isn't found.
 *
 * For now, this is just pulling the config locally; in future this method will fetch this data from the CMS.
 *
 * @param hostname e.g. google.com - the protocol and port will be stripped
 */
export const fetchBrandConfigOrFail = async (hostname: string) => {
  const brandConfig = await fetchBrandConfig(hostname);

  if (!brandConfig) {
    throw new MissingBrandConfigError(hostname);
  }

  return brandConfig;
};
