import { css } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import React from 'react';

import { useTheme } from '../theme';

interface DrawerProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  trigger: React.ReactNode;
  children: React.ReactNode;
  onOpenAutoFocus?: (event: Event) => void;
}

export const SideDrawer = (props: DrawerProps) => {
  const {
    componentProperties: { sideDrawer: sideDrawerStyles, modals },
  } = useTheme();
  return (
    <Dialog.Root open={props.isOpen} onOpenChange={props.setOpen}>
      {props.trigger}
      <AnimatePresence>
        {props.isOpen ? (
          // forceMount allows us to take control over exit animations
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild>
              <motion.div
                css={css({
                  background: 'rgba(0, 0, 0, 0.50)',
                  zIndex: 1200,
                  position: 'fixed',
                  inset: 0,
                  ...modals?.overlay,
                })}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.3, ease: 'easeIn' },
                }}
                transition={{ duration: 0.4, ease: 'easeOut' }}
              />
            </Dialog.Overlay>
            <Dialog.Content
              css={css`
                position: fixed;
                top: 0;
                inset-inline-end: 0;
                overflow-y: hidden;
                width: 100%;
                max-width: 375px;
                height: 100%;
                background-color: ${sideDrawerStyles?.backgroundColor ??
                '#ffffff'};
                z-index: 1200;
              `}
              onOpenAutoFocus={props.onOpenAutoFocus}
              asChild
            >
              <motion.div
                key="ModalContent"
                initial={{
                  translateX: 'calc(var(--content-direction-factor) * 100vw)',
                }}
                animate={{ translateX: 0 }}
                exit={{
                  translateX: 'calc(var(--content-direction-factor) * 100vw)',
                  transition: { duration: 0.3, ease: 'easeIn' },
                }}
                transition={{ duration: 0.4, ease: 'easeOut' }}
              >
                <Dialog.Close
                  css={css`
                    position: absolute;
                    top: 0;
                    inset-inline-end: 0;
                    width: 50.38px;
                    height: 48.38px;
                    cursor: pointer;
                    &:before,
                    &:after {
                      content: '';
                      width: 1px;
                      height: 25px;
                      background-color: ${sideDrawerStyles?.closeButton
                        ?.color ?? '#3e3a37'};
                      position: absolute;
                      top: 11px;
                    }
                    &:before {
                      transform: rotate(45deg);
                      inset-inline-end: 24px;
                    }
                    &:after {
                      transform: rotate(-45deg);
                      inset-inline-start: 25px;
                    }
                  `}
                />
                {props.children}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        ) : null}
      </AnimatePresence>
    </Dialog.Root>
  );
};

SideDrawer.Close = Dialog.Close;
SideDrawer.Trigger = Dialog.Trigger;
