import { css } from '@emotion/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  getTwoMonthDateRange,
  isPeriodLongerThan,
} from '@/booking/helpers/date';
import { useCalendarAvailability } from '@/booking/hooks/use-calendar-availability.hook';
import { useTranslation } from '@/i18n';
import { AlertCard } from '@/ui/layout';
import { Text } from '@/ui/typography';

import { SearchFormFields } from '../validators/search-form.validator';

dayjs.extend(isBetween);

// Returns either booking exceeded max length or unavailability message alert
export const BookingRangeAlert = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<SearchFormFields>();

  const [from, to, hotelCode] = watch(['from', 'to', 'hotelCode']);

  const { startDate, endDate } = getTwoMonthDateRange(from);

  // Taking advantage of the cache to avoid unnecessary requests
  const { data: calendarAvailabilty } = useCalendarAvailability({
    hotelReferenceId: hotelCode,
    startDate,
    endDate,
    guests: {
      adults: 1,
    },
    numberOfRooms: 1,
  });

  const hasUnavailableDays = calendarAvailabilty?.dates
    ?.filter((date) => {
      const currentDate = dayjs(date.date);
      // Check if it's within the `from` and `to` range, inclusive
      return currentDate.isBetween(dayjs(from), dayjs(to), 'day', '[]');
    })
    // Remove the last day as it is the checkout day
    .slice(0, -1)
    // Check if any date in the filtered period is unavailable
    .some((date) => !date.available);

  const hasExceededMaxBookingLength =
    hotelCode === 'orientexpress.laminerva-rome' &&
    isPeriodLongerThan(from, to, 9);

  return (hasExceededMaxBookingLength || hasUnavailableDays) && from && to ? (
    <AlertCard
      type="warning"
      css={css({
        padding: '12px 15px',
        marginTop: '30px',
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.15)',

        '@media (max-width: 639px)': {
          marginTop: 0,
        },
      })}
    >
      <Text style="subheader3">
        {t(
          `search:form.${
            hasExceededMaxBookingLength
              ? 'maxBookingLength'
              : 'unavailabilityMessage'
          }`
        )}
      </Text>
    </AlertCard>
  ) : null;
};
