import React from 'react';
import { useFormContext } from 'react-hook-form';

import { isPeriodLongerThan } from '@/booking/helpers/date';
import { useTranslation } from '@/i18n';
import { Button } from '@/ui/controls';

import type { SearchFormFields } from '../validators/search-form.validator';

export const SearchButton = ({ isLoading }: { isLoading?: boolean }) => {
  const { t } = useTranslation('search');

  const {
    watch,
    formState: { errors, isSubmitted },
  } = useFormContext<SearchFormFields>();

  const [from, to, hotelReferenceId] = watch(['from', 'to', 'hotelCode']);

  const hasExceededMaxBookingLength =
    hotelReferenceId === 'orientexpress.laminerva-rome' &&
    isPeriodLongerThan(from, to, 9);

  return (
    <Button
      isDisabled={
        Boolean(errors.roomOccupancy) ||
        Boolean(errors.from) ||
        Boolean(errors.hotelCode) ||
        (errors.roomOccupancy && isSubmitted) ||
        hasExceededMaxBookingLength
      }
      isLoading={isLoading}
      type="submit"
      testId="search-button"
    >
      {t('form.searchButtonLabel')}
    </Button>
  );
};
