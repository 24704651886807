import dayjs from 'dayjs';

import { unmarshalTimeRange } from '@/api';
import { BookingDayTimePart } from '@/em-api-client-typescript-fetch';

export const formatDate = (date: dayjs.Dayjs) => date.format('YYYY-MM-DD');

/**
 * 1 hour ranges are specified in the API as "BookingDayTimePart"s.
 * This function takes the enum, plus a date and timezone, then returns
 * a local Date object for either the start, or end of that range (based on the value of `take`).
 * @param args
 * @returns
 */
export const getLocalTimePart = (args: {
  date: string;
  dateTimePart: BookingDayTimePart;
  timezone: string;
  take: 'start' | 'end';
}) => {
  const time = unmarshalTimeRange(args.dateTimePart)[args.take];
  return dayjs(`${args.date}T${time}Z`).tz(args.timezone);
};

/**
 * Calculates and returns the start and end dates for a two-month range based on the provided date.
 * Adjusts specific months to ensure the API supports the two-month range.
 *
 * @param from - The starting date as a string in 'YYYY-MM-DD' format.
 * @returns An object containing the startDate and endDate as strings in 'YYYY-MM-DD' format.
 */
export const getTwoMonthDateRange = (from: string) => {
  const getFormattedMonth = (date: string) => {
    const month = dayjs(date).month();

    // We are getting the month index and checking the interval like [Jan, Feb], [Mar, Apr], etc... to always fetch the same 2 months because it's the API range limitation and also to take advantage of the caching (e.g. 01/01 to 02/28, 03/01 to 04/30, etc...)
    const monthsToAdjust = [1, 3, 5, 7, 9, 11];

    return monthsToAdjust.includes(month)
      ? formatDate(dayjs(date).startOf('month').subtract(1, 'month'))
      : formatDate(dayjs(date).startOf('month'));
  };

  // Select the first day of the selected month on calendar or today if the current month is in the past
  const getStartDate = (date: string) => {
    return dayjs(date).isBefore(dayjs(), 'day')
      ? formatDate(dayjs())
      : formatDate(dayjs(date));
  };

  const formattedMonth = getFormattedMonth(from);
  const startDate = getStartDate(formattedMonth);
  const endDate = formatDate(
    dayjs(formattedMonth).add(1, 'month').endOf('month')
  );

  return { startDate, endDate };
};

/**
 * Checks if the difference between two dates is more to a specified number of days.
 *
 * @param {string} from - The start date (YYYY-MM-DD).
 * @param {string} to - The end date (YYYY-MM-DD).
 * @param {number} days - The maximum number of days.
 * @returns {boolean} - True if conditions are met, otherwise false.
 */
export const isPeriodLongerThan = (from: string, to: string, days: number) => {
  return dayjs(to).diff(dayjs(from), 'days') > days;
};
