import * as Sentry from '@sentry/react';

import {
  getIsErrorReportingEnabled,
  getPublicEnvironmentConfig,
} from '@/env/client/config';
import { defaultLogger } from '@/logging';

/**
 * Returns true if given event has an exception containing a trace from any filename matching `gtm.js`.
 */
const eventContainsGTMFileErrors = (event: Sentry.Event) =>
  event.exception?.values?.some((value) =>
    value.stacktrace?.frames?.some(
      (frame) =>
        frame.filename?.includes('gtm.js') ||
        // OneTrust
        frame.filename?.includes('otBannerSdk.js') ||
        frame.filename?.includes('otSDKStub.js')
    )
  );

const eventContainsGTMQuerySelectorErrors = (event: Sentry.Event) =>
  event.exception?.values?.some((error) =>
    error.value?.match(
      /null is not an object \(evaluating 'document\.querySelector\(".*\.innerText'\)/
    )
  );

/**
 * Does this event seem to match errors emitted from GTM selectors?
 *
 * We're getting a lot of error-noise from outdated GTM page selectors. Ideally we wouldn't exclude
 * these as it does usually indicate an issue, but as it's down to Found to resolve them
 * (and our quotas are being used-up), we're filtering these out.
 */
const eventContainsGtmErrors = (event: Sentry.Event) =>
  eventContainsGTMFileErrors(event) ||
  eventContainsGTMQuerySelectorErrors(event);

export function setupClientSideSentry() {
  const env = getPublicEnvironmentConfig();

  if (typeof window === 'undefined') {
    defaultLogger.debug('Sentry disabled in non-browser environments');
    return;
  }

  if (getIsErrorReportingEnabled()) {
    Sentry.init({
      dsn: env.NEXT_PUBLIC_SENTRY_DSN,
      environment: env.NEXT_PUBLIC_ENVIRONMENT || 'local',
      tracesSampleRate: 1.0,
      debug: env.NEXT_PUBLIC_ENVIRONMENT !== 'live',
      release: env.NEXT_PUBLIC_VERSION,
      beforeSend(event) {
        if (eventContainsGtmErrors(event)) {
          defaultLogger.info(
            '[sentry] swallowing event as it appears to come from a third-party script',
            event
          );
          return null;
        }
        return event;
      },
    });
    console.info('Sentry setup');
  } else {
    console.info(
      'Sentry reporting disabled (missing DSN). Skipping initialisation.'
    );
  }
}
