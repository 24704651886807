import { ConfigServiceApi, Configuration } from '@ennismore/config-client';

import { axios } from '@/api/axios-instance';
import { ResponseValidationError } from '@/api/errors/api-response-validation.error';
import { EnnismoreAPIClientConfig } from '@/api/interfaces';

import { HotelConfiguration, HotelConfigurationModel } from '../models';

/**
 * Client for em-config/CMS
 */
export class ConfigServiceClient {
  protected client: ConfigServiceApi;

  protected get options() {
    return {
      headers: {
        'X-Api-Key': this.credentials.apiKey,
        'X-Skip-Redirect': this.credentials.skipRedirect,
      },
    };
  }

  constructor(private readonly credentials: EnnismoreAPIClientConfig) {
    const config = new Configuration({
      basePath: credentials.baseUrl.replace('/v2', ''),
      apiKey: credentials.apiKey,
    });

    this.client = new ConfigServiceApi(config, undefined, axios);
  }

  /**
   * Fetch an extended hotel configuration, sourced from the platform CMS.
   * @param hotelReferenceId
   * @param locale
   * @returns Extended hotel configuration
   */
  async getHotelConfig({
    hotelReferenceId,
    locale,
  }: {
    hotelReferenceId: string;
    locale: string;
  }): Promise<HotelConfiguration> {
    const response = await this.client.configServiceGetHotel(locale, {
      hotelReferenceId,
    });

    const hotel = HotelConfigurationModel.safeParse(response.data);

    if (!hotel.success) {
      throw new ResponseValidationError(hotel.error);
    }

    return hotel.data;
  }
}
