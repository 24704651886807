import { useSkipRedirectSwitch } from '@/availability-fallback/hooks';
import { useActiveBrandConfig } from '@/brand';
import { useAppEvents } from '@/events';
import { useExperimentStatus } from '@/flags/use-experiments';
import type { IOccupancy } from '@/hotel';
import { useLocale } from '@/i18n';
import { trpcClient } from '@/trpc/client';

import { AvailabilityEvents } from '../events';
import { SearchQueryTransformers } from '../helpers/transformers';
import type { IAvailabilitySearchQueryInstance } from '../models/availability-search-query.model';
import { useBedroomAvailability } from './use-bedroom-availability.hook';

export const useAvailabilitySearchResults = (
  query: IAvailabilitySearchQueryInstance,
  occupancy: IOccupancy,
  options: { onRequestRedirect: (redirectUrl: string) => void }
) => {
  const locale = useLocale();

  const { ohipDiscountCodeType } = useActiveBrandConfig();
  const events = useAppEvents<AvailabilityEvents>();
  const [skipRedirect] = useSkipRedirectSwitch();

  const isAutoRedirectFlagEnabled = useExperimentStatus('auto-redirect');

  const requestQuery = SearchQueryTransformers.toV1GetAvailabilityRequest({
    query,
    occupancy,
    locale,
    ohipDiscountCodeType,
  });

  return useBedroomAvailability(requestQuery, {
    // This function is called after retries have been attempted
    // TODO: Move this to an Error Boundary higher up the tree - add unit tests
    async onError(error) {
      function reportFailureWithResolution(
        resolution: 'error-shown' | 'redirected'
      ) {
        // Emit search error event
        events.emit('searchError', { error, query, skipRedirect });

        // Report the failure via tRPC
        trpcClient.telemetry.reportAvailabilityFailure.mutate({
          domainErrorCode: error.code,
          availabilityQuery: requestQuery,
          resolution,
        });
      }

      // Only auto redirect if the feature flag is enabled, and the error code is a redirectable one.
      const shouldAutoRedirect =
        !skipRedirect && isAutoRedirectFlagEnabled && error.isRedirectable;

      if (error.isRedirectInstruction || shouldAutoRedirect) {
        const redirectUrl = await trpcClient.fallback.fallbackUrl.query({
          query,
          language: locale.language,
        });

        // Emit search redirect tracking error
        events.emit('searchRedirect', { cause: error, query });

        // Ask the parent component to perform the redirect
        options.onRequestRedirect(redirectUrl);
      } else {
        // We're either unable to redirect, or it's not appropriate for this context
        reportFailureWithResolution('error-shown');
      }
    },
  });
};
