import React, { CSSProperties } from 'react';

interface PageWrapperProps {
  isFlushOnMobile?: boolean;
  maxWidth?: CSSProperties['maxWidth'];
  alignContent?: 'left' | 'center';
  children: React.ReactNode;
}

export const PageWrapper = (props: PageWrapperProps) => (
  <div
    css={{
      maxWidth: props.maxWidth ?? '1280px',
      margin: props.alignContent === 'left' ? '0 0 0 80px' : 'auto',
      padding: '0 20px',
      boxSizing: 'content-box',
      '@media all and (max-width: 730px)': {
        margin: 'auto',
        padding: props.isFlushOnMobile ? '0' : '0 20px',
      },
    }}
  >
    {props.children}
  </div>
);

export const SmallPageWrapper = (props: PageWrapperProps) => (
  <PageWrapper {...props} maxWidth={props.maxWidth ?? '420px'} />
);
