import { AvailabilityEvents } from '@/availability/events';
import { IAvailabilitySearchQueryInstance } from '@/availability/models';
import { BookingCheckoutEvents } from '@/booking-checkout/events';
import { BookingPaymentEvents } from '@/booking-payment/events';
import { IBookingInstance } from '@/booking/models';
import { getFloatValue } from '@/common/helpers/numbers';
import { CoreAppEvents } from '@/core/events';
import { AppEventEmitter } from '@/events';
import { FeatureFlagEvents } from '@/flags/events';
import { IHotelModelInstance } from '@/hotel';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { InternationalizationEvents } from '@/i18n/events';

export type GTMV2Events = FeatureFlagEvents &
  BookingPaymentEvents &
  BookingCheckoutEvents &
  AvailabilityEvents &
  InternationalizationEvents &
  CoreAppEvents;

/**
 * Listens for app events, feeding and mapping relevant ones into GTM.
 *
 * Implemented according to spec: https://docs.google.com/document/d/1tpXFSuiLaArE0n1laPY0xyt60p03kG1IVv7RCtgB9Rw
 *
 * @param events
 * @param pushToDataLayer
 * @returns A cleanup function that removes all listeners specificially created in this set.
 */
export const setupGTMDataLayerEventFeed = (
  events: AppEventEmitter<GTMV2Events>,
  pushToDataLayer: (event: unknown) => void
) => {
  const listeners = [
    events.addListener(
      'bookingPayment',
      ({ hotel, booking, email, firstName, lastName }) => {
        const event: GA4PurchaseEvent = {
          event: 'purchase',
          email: email.toLowerCase(),
          first_name: firstName,
          last_name: lastName,
          ...mapBooking(hotel, booking, true),
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener('searchError', ({ error, query, skipRedirect }) => {
      const event: GA4SearchErrorEvent = {
        event: 'search_error',
        error_details: {
          error_code: error.code,
          hotel_reference_id: resolveHotelReferenceIdFromHotelCode(
            query.hotelCode
          ),
          skip_redirect: skipRedirect,
        },
      };

      pushToDataLayer(event);
    }),

    events.addListener('searchRedirect', ({ cause, query }) => {
      const event: GA4SearchRedirectEvent = {
        event: 'search_redirect',
        redirect_details: {
          error_code: cause.code,
          hotel_reference_id: resolveHotelReferenceIdFromHotelCode(
            query.hotelCode
          ),
        },
      };

      pushToDataLayer(event);
    }),

    events.addListener(
      'additionalInfoFormProgression',
      ({ hotel, booking }) => {
        // Track only non-empty add-on data
        if (!booking.addOns || booking.addOns.length === 0) return;

        const event: GA4AddToCartEvent = {
          event: 'add_to_cart',
          ...mapBooking(hotel, booking),
          ecommerce: {
            items: booking.addOns.map((addOn, index) => ({
              item_id: addOn.id.toLocaleLowerCase(),
              item_name: hotel.referenceId,
              item_brand: hotel.brandReferenceId,
              item_category: 'add-on',
              item_variant: addOn.name.toLocaleLowerCase().trim(),
              price:
                Math.round(
                  addOn.cost.amountAfterTax.value / addOn.calculatedQuantity
                ) / 100,
              index,
              quantity: addOn.calculatedQuantity,
            })),
          },
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener(
      'selectRoom',
      ({ room, roomIndex, availability, hotel }) => {
        const roomTotal = availability.availability.find(
          (rate) => rate.rateCode.requestRateCode === room.rateCode
        )?.grandTotal;

        const event: GA4AddToCartEvent = {
          event: 'add_to_cart',
          version: '3',
          destination: hotel.referenceId,
          city: hotel.location.address.city.toLowerCase(),
          country: hotel.location.address.countryCode.toLowerCase(),
          check_in_date: room.checkInDate,
          check_out_date: room.checkOutDate,
          adult: room.guests.adults,
          child: room.guests.children ?? 0,
          ecommerce: {
            items: [
              {
                item_id: room.roomCode.toLowerCase(),
                item_name: hotel.referenceId,
                item_brand: hotel.brandReferenceId,
                item_variant: availability.room.name.toLowerCase(),
                item_category: 'room',
                item_category2:
                  availability.availability[0].rateCode.name?.toLowerCase(),
                price: getFloatValue(
                  roomTotal?.value || 0,
                  roomTotal?.decimal || 0
                ),
                index: roomIndex,
                quantity: 1,
              },
            ],
          },
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener('additionalInfoPageLoad', ({ hotel, booking }) => {
      const event: GA4BeginCheckoutEvent = {
        event: 'begin_checkout',
        ...mapBooking(hotel, booking),
      };

      pushToDataLayer(event);
    }),

    events.addListener(
      'paymentFormSubmittedSuccessfully',
      ({ hotel, booking, paymentType }) => {
        const event: GA4AddPaymentInfoEvent = {
          event: 'add_payment_info',
          payment_type: paymentType.toLowerCase() as Lowercase<
            typeof paymentType
          >,
          ...mapBooking(hotel, booking),
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener(
      'checkRoomAvailability',
      ({ hotel, query, metadata }) => {
        const event: GA4CheckRoomAvailabilityEvent = {
          event: 'room_check_availability',
          version: '3',
          destination: hotel.referenceId,
          placement: metadata.placement,
          ...mapQuery(query),
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener('roomsImpression', ({ availability, hotel }) => {
      interface Event {
        event: 'view_item_list';
        ecommerce: {
          destination: string;
          items: {
            item_id: string;
            item_name: string;
            index: number;
            item_brand: string;
            item_category: 'room';
            price: string;
          }[];
        };
      }

      const event: Event = {
        event: 'view_item_list',
        ecommerce: {
          destination: hotel.referenceId,
          items: availability.map((room, index) => ({
            item_id: room.room.code,
            index,
            item_name: hotel.referenceId,
            item_variant: room.room.name,
            item_brand: hotel.brandReferenceId,
            item_category: 'room',
            price: room.cheapestRate?.fixedValue || '',
          })),
        },
      };

      pushToDataLayer(event);
    }),

    events.addListener(
      'receivedPartialOrNoAvailability',
      ({ results, hotel, query }) => {
        const event: GA4NoAvailabilityEvent = {
          event: 'no_availability',
          version: '3',
          destination: hotel.referenceId,
          placement: 'booking engine search',
          no_availability_reason: results.isHotelFullyBooked
            ? 'Fully Booked'
            : 'Partially Booked',
          no_availability_rooms: results.rooms
            .filter((room) => !room.isRoomAvailable)
            .map((room) => room.room.name),
          ...mapQuery(query),
        };

        pushToDataLayer(event);
      }
    ),

    events.addListener('changeLanguage', (event) => {
      pushToDataLayer({
        event: 'language_change',
        version: '3',
        language: event.locale,
      });
    }),

    events.addListener('navigation', (event) => {
      pushToDataLayer({
        event: 'navigation',
        navigation_location: event.location,
        navigation_item: event.originator,
      });
    }),

    // TODO: Uncomment this. There's a loading race condition which happens
    // here - these listeners aren't setup at the time of the first eval.
    // events.addListener('experimentEvaluation', (event) => {

    // }),
  ];

  /**
   * Remove all listeners added by this function specifically.
   */
  return () => listeners.forEach((cleanup) => cleanup());
};

function mapQuery(query: IAvailabilitySearchQueryInstance) {
  const withCode = query.rateCode
    ? {
        code: query.rateCode,
      }
    : {};

  return {
    adult: query.rooms.reduce(
      (totalAdultCount, room) => totalAdultCount + room.adults,
      0
    ),
    child: query.rooms.reduce(
      (totalChildCount, room) => totalChildCount + room.children,
      0
    ),
    check_in_date: query.from,
    check_out_date: query.to,
    ...withCode,
  };
}

function mapBooking(
  hotel: IHotelModelInstance,
  booking: IBookingInstance,
  includeTransactionDetail = false
): GA4EcommerceEvent {
  const total = booking.firstTotalChargeBreakdown.grandTotal;
  const currency = hotel.payments.currencyCode;
  const totalTaxes =
    booking.firstTotalChargeBreakdown.costBreakdown?.totalTaxesFee;

  return {
    version: '3',
    destination: hotel.referenceId,
    city: hotel.location.address.city.toLowerCase(),
    country: hotel.location.address.countryCode.toLowerCase(),
    adult: booking.totalAdultCount,
    child: booking.totalChildCount,
    check_in_date: booking.firstRoomStay.from,
    check_out_date: booking.firstRoomStay.to,
    ecommerce: {
      ...(includeTransactionDetail
        ? {
            transaction_id: booking.pmsId,
            booking_id: booking.id,
            currency,
            value: getFloatValue(total?.value || 0, total?.decimal || 0),
            tax: getFloatValue(
              totalTaxes?.value || 0,
              totalTaxes?.decimal || 0
            ),
          }
        : {}),
      items: booking.roomStay.map((room, index) => {
        const roomTotal = room.firstRatePlan.chargeBreakdown.grandTotal;
        return {
          index,
          item_name: hotel.referenceId,
          item_variant: room.firstRoomDetail.name.toLowerCase(),
          quantity: 1,
          item_brand: hotel.brandReferenceId,
          item_category: 'room',
          item_category2:
            booking.firstRoomStay.ratePlan[0].rateName?.toLowerCase(),
          item_id: room.firstRoomDetail.code.toLowerCase(),
          price: getFloatValue(roomTotal?.value || 0, roomTotal?.decimal || 0),
        };
      }),
    },
  };
}

interface GA4VersionedEvent {
  version: '3';
}

interface GA4SearchErrorEvent {
  event: 'search_error';
  error_details: {
    error_code: string;
    hotel_reference_id: string;
    /**
     * Has the current user initiating this request skipped redirect? This indicates an internal business tester.
     */
    skip_redirect: boolean;
  };
}

interface GA4SearchRedirectEvent {
  event: 'search_redirect';
  redirect_details: {
    error_code: string;
    hotel_reference_id: string;
  };
}

type GA4PurchaseEvent = {
  event: 'purchase';
  first_name: string;
  last_name: string;
} & GA4EcommerceEvent;

type GA4AddToCartEvent = {
  event: 'add_to_cart';
} & GA4EcommerceEvent;

type GA4BeginCheckoutEvent = {
  event: 'begin_checkout';
} & GA4EcommerceEvent;

type GA4AddPaymentInfoEvent = {
  event: 'add_payment_info';
  payment_type: 'credit card' | 'apple pay' | 'google pay';
} & GA4EcommerceEvent;

type GA4CheckRoomAvailabilityEvent = {
  event: 'room_check_availability';
  code?: string;
  placement: string; // context from where room checking is triggered, e.g. 'Modify search'
} & GA4VersionedEvent &
  GA4StayDetail;

type GA4NoAvailabilityEvent = {
  event: 'no_availability';
  no_availability_reason: 'Fully Booked' | 'Partially Booked';
  no_availability_rooms: string[];
  placement: string; // where Check Availability was clicked
} & GA4VersionedEvent &
  GA4StayDetail;

interface GA4StayDetail {
  destination: string; // hotel reference id, e.g. 'hoxton.lloyd-amsterdam'
  check_in_date: string; // YYYY-MM-DD
  check_out_date: string; // YYYY-MM-DD
  adult: number;
  child: number;
}

interface GA4EcommerceItem {
  /**
   * Room code
   */
  item_id: string;
  /**
   * Hotel name (e.g. holborn)
   */
  item_name: string;
  index: number; // assuming index of item in array..?
  /**
   * Brand name
   */
  item_brand: string;
  /**
   * e.g. 'room'
   */
  item_category: 'room' | 'add-on';
  /**
   * Room rate name
   */
  item_category2?: string;
  /**
   * Room or add-on name
   */
  item_variant: string;
  /**
   * Total price as float (including taxes?)
   */
  price: number;
  /**
   * This will always be one when quantity represents rooms AFAIK. One room = one line entry.
   * But might be many for add-ons, especially if it's a rocker.
   */
  quantity: number;
}

/**
 * Represents a common ecommerce object, specified by Braidr (data agency).
 * See: https://docs.google.com/document/d/1tpXFSuiLaArE0n1laPY0xyt60p03kG1IVv7RCtgB9Rw
 */
interface GA4EcommerceEvent extends GA4VersionedEvent, GA4StayDetail {
  /**
   * e.g. london
   */
  city: string;
  /**
   * e.g. GB
   */
  country: string;
  /**
   * Used for Enhanced conversion in Google Ads.
   * Google Ads is hashing the email before sending it to their servers, so no need to hash it.
   */
  email?: string;
  ecommerce: {
    /**
     * The property management system ID
     */
    transaction_id?: string;
    /**
     * The booking ID
     */
    booking_id?: string;
    /**
     * e.g. USD
     */
    currency?: string;
    /**
     * Total value of booking as a float - eg. 245.00 (unsure whether including tax, or excluding?)
     */
    value?: number;
    /**
     * Total amount of tax
     */
    tax?: number;
    /**
     * Promo code used when booking
     */
    coupon?: string;
    items: GA4EcommerceItem[];
  };
}
