import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import React from 'react';

import { useActiveBrandConfig } from '@/brand';

import { LocalisedFontProvider } from '../typography';
import { ThemeProvider } from './ThemeProvider.component';
import type { AvailableThemeKey } from './available-theme-keys.type';
import { ThemeSwitcherContext } from './theme-switcher.context';

interface ThemeSwitcherProviderProps {
  children: React.ReactNode;
}

export const ThemeSwitcherProvider = observer(
  (props: ThemeSwitcherProviderProps) => {
    const defaultThemeKey = useActiveBrandConfig().themeKey;
    const [activeThemeKey, setActiveThemeKey] =
      useState<AvailableThemeKey>(defaultThemeKey);
    const resetThemeKey = () => setActiveThemeKey(defaultThemeKey);

    return (
      <ThemeSwitcherContext.Provider
        value={{
          setActiveThemeKey,
          resetThemeKey,
          activeThemeKey,
          defaultThemeKey,
        }}
      >
        <ThemeProvider themeKey={activeThemeKey}>
          <LocalisedFontProvider>{props.children}</LocalisedFontProvider>
        </ThemeProvider>
      </ThemeSwitcherContext.Provider>
    );
  }
);

export default ThemeSwitcherProvider;
