import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import React from 'react';
import { useId } from 'react';

import { CityTaxLineItemOverride } from '@/booking/components/CityTaxLineItemOverride.component';
import { useTranslation } from '@/i18n';
import { LoadingIndicator, TranslatedErrorMessage } from '@/ui/controls';
import { Card, CardHeadingPill, CardWithHeading, Divider } from '@/ui/layout';
import { CardHeading } from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

import { useBookingSummary, useDisplayCityTaxOverride } from '../hooks';
import { BookingRequest } from '../interfaces';
import { IBookingInstance } from '../models';
import {
  BookingBreakdownPrimaryTable,
  BookingBreakdownRoomsTable,
  BookingBreakdownStayTotalsTable,
  BookingBreakdownTaxTotalsTable,
} from './BookingBreakdown.component';

function BookingCardHeadingPill(props: { status: IBookingInstance['status'] }) {
  const { t } = useTranslation('bookingManagement');
  switch (props.status) {
    case 'CANCELLED_BY_USER':
      return (
        <CardHeadingPill label={t('managementCard.cancelledStatusLabel')} />
      );
    case 'CANCEL_RECEIVED':
      return <CardHeadingPill label={t('managementCard.cancellingLabel')} />;
    default:
      return null;
  }
}

interface BookingBreakdownCardProps extends BookingRequest {
  footer?: React.ReactNode;
  cardLabel?: string;
}

/**
 * @TODO Split this out into two cards: cancelled booking card and booking breakdown card.
 */
export const BookingBreakdownCard: React.FC<BookingBreakdownCardProps> = (
  props
) => {
  const {
    data: booking,
    isLoading,
    isFetched,
    error,
  } = useBookingSummary(props);
  const id = useId();
  const { t } = useTranslation('bookingManagement');
  const displayCityTaxOverride = useDisplayCityTaxOverride(props.hotelSlug);
  const {
    componentProperties: { bookingManagement: bookingManagementStyles },
  } = useTheme();

  if (isLoading && !isFetched) {
    return (
      <Card>
        <LoadingIndicator style="dark" />
      </Card>
    );
  }

  if (error) {
    return (
      <Card rounded>
        <TranslatedErrorMessage
          dictionaryKey={error.messageTranslationKey}
          textAlign="center"
        />
      </Card>
    );
  }

  const opacity = booking?.isCancelled
    ? bookingManagementStyles?.bookingCard?.cancelled?.opacity ?? 0.35
    : 1;

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1, transition: { ease: 'easeInOut' } }}
    >
      <CardWithHeading
        css={css(bookingManagementStyles?.bookingCard)}
        heading={
          <CardHeading
            css={css({
              ...bookingManagementStyles?.bookingCard?.header,
              backgroundColor: booking?.isCancelled
                ? bookingManagementStyles?.bookingCard?.header?.cancelled
                    ?.backgroundColor
                : bookingManagementStyles?.bookingCard?.header?.backgroundColor,
              span: {
                color: booking?.isCancelled
                  ? bookingManagementStyles?.bookingCard?.header?.cancelled
                      ?.color
                  : bookingManagementStyles?.bookingCard?.header?.color ??
                    'inherit',
                fontSize:
                  bookingManagementStyles?.bookingCard?.header?.fontSize,
              },
              div: {
                border: booking?.isCancelled
                  ? bookingManagementStyles?.bookingCard?.header?.cancelled
                      ?.pillowBorder
                  : undefined,
              },
            })}
            title={
              props.cardLabel ||
              t('managementCard.title', { bookingId: booking?.pmsId })
            }
            rightAction={
              booking && <BookingCardHeadingPill status={booking?.status} />
            }
          />
        }
        layoutId={id}
      >
        <Stack
          s="xs"
          css={{ rowGap: bookingManagementStyles?.bookingCard?.body?.rowGap }}
        >
          <div style={{ opacity }}>
            <BookingBreakdownPrimaryTable {...props} />
          </div>
          <Divider />
          <BookingBreakdownRoomsTable
            {...props}
            wrapElementsInContainer={false}
            pricePerNightMode="average"
            displayConfirmationNumber={true}
            separator={<Divider />}
            css={css({ opacity })}
          />
          <Divider />
          <div style={{ opacity }}>
            <BookingBreakdownTaxTotalsTable {...props} />
          </div>
          <Divider />
          <div style={{ opacity }}>
            <BookingBreakdownStayTotalsTable {...props} />
          </div>
          {displayCityTaxOverride ? (
            <>
              <Divider />
              <div style={{ opacity }}>
                <CityTaxLineItemOverride hotelReferenceId={props.hotelSlug} />
              </div>
            </>
          ) : null}
          <div css={css(bookingManagementStyles?.bookingCard?.footer)}>
            {props.footer}
          </div>
        </Stack>
      </CardWithHeading>
    </motion.div>
  );
};
