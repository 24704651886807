import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@/box';
import AnchorButton from '@/hox-core/components/controls/AnchorButton.component';
import { useTranslation } from '@/i18n';
import { Spacer } from '@/ui/spacing';
import { BodySmall } from '@/ui/typography';

import type { SearchFormFields } from '../validators/search-form.validator';
import RateCodeTextInput from './RateCodeTextInput.component';

interface RateCodeTextInputProps {
  defaultValue?: SearchFormFields['rateCode'];
}

export const RateCodeTextInputContainer = ({
  defaultValue,
}: RateCodeTextInputProps) => {
  const [isRateCodeFieldVisible, setIsRateCodeFieldVisible] = useState<boolean>(
    Boolean(defaultValue)
  );
  const { t } = useTranslation('search');
  const { watch } = useFormContext<Pick<SearchFormFields, 'dayUse'>>();

  const dayUse = watch('dayUse');

  if (dayUse.length > 0) return null;

  return (
    <>
      {isRateCodeFieldVisible ? (
        <Box width={[1, 3 / 12]} paddingInlineEnd={[0, 40]}>
          <RateCodeTextInput autoFocus />
        </Box>
      ) : (
        <>
          <BodySmall>
            <AnchorButton onClick={() => setIsRateCodeFieldVisible(true)}>
              {t('form.rateCodeLabel')}
            </AnchorButton>
          </BodySmall>
          <Spacer s="xs" />
        </>
      )}
      <Spacer s="xxs" />
    </>
  );
};
