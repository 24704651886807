import { useEffect } from 'react';

import { trackExpiredEvent } from '@/analytics/analytics';
import {
  BookingCheckoutNavigator,
  useBookingCheckout,
} from '@/booking-checkout';

import { useBookingSummary } from './use-booking-summary.hook';

export type BookingCheckoutStep =
  | 'search'
  | 'additional-info'
  | 'payment'
  | 'confirmation'
  | 'booking-root';

export const useRedirectToCorrectStep = ({
  hotelSlug,
  bookingId,
  currentStep,
  navigator,
}: {
  hotelSlug: string;
  bookingId: string;
  currentStep: BookingCheckoutStep;
  navigator: BookingCheckoutNavigator;
}) => {
  const { data: booking, error } = useBookingSummary({
    bookingId,
    hotelSlug,
  });
  const checkout = useBookingCheckout(bookingId);

  useEffect(() => {
    if (
      booking?.status === 'EXPIRED' ||
      booking?.status === 'CANCELLED' ||
      error?.isBookingNotFound
    ) {
      // Track within Google Analytics
      trackExpiredEvent(window.location.pathname);

      // Pushed to the expired booking route
      navigator.expiredPage();

      return;
    }

    // Do nothing if the booking hasn't loaded yet
    if (!booking) {
      return;
    }

    // If the booking is completed or was cancelled by the user
    // after it was completed, redirect to confirmation step.
    if (
      (booking.isConfirmed || booking.status === 'CANCELLED_BY_USER') &&
      currentStep !== 'confirmation'
    ) {
      navigator.confirmationPage();
    }

    // If we're on the confirmation page and the booking isn't confirmed yet, return to payment page
    // Yes, this is gross.
    if (
      currentStep === 'confirmation' &&
      !booking.isConfirmed &&
      booking.status !== 'CANCELLED_BY_USER'
    ) {
      navigator.paymentPage();
    }

    // Pending page contains a check to render a fatal payment error message.
    if (booking.status === 'PAYMENT_ERROR') {
      navigator.pendingPage();
    }

    // If we're on the payment page, but there's no local configuration, the user needs to add additional information
    if (
      currentStep === 'payment' &&
      !checkout.config.isAdditionalInfoStepCompleted
    ) {
      navigator.additionalInformationPage();
    }
  }, [
    bookingId,
    currentStep,
    booking?.status,
    booking?.isConfirmed,
    error?.isBookingNotFound,
  ]);
};
