import * as React from 'react';

import { ScreenReaderAnnouncement } from '@/accessibility/components';
import { OnlyDesktop } from '@/common';
import { LoadingIndicator } from '@/ui/controls';
import { Spacer } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';
import { Heading } from '@/ui/typography';

import { RateCodeUnavailableCard, RoomImageDisclaimer } from '.';

interface HotelAvailabilitySearchResultsProps {
  children?: React.ReactNode;
  title?: string;
  isLoading?: boolean;
  rateCodeSearchErrorMessage: string | undefined;
  resultRoomCount?: number;
}

export const HotelAvailabilitySearchResults = (
  props: HotelAvailabilitySearchResultsProps
) => {
  const {
    componentProperties: { search },
  } = useTheme();

  return (
    <div
      css={{
        height: '100%',
        '@media all and (max-width: 730px)': {
          paddingBottom: '64px',
          '.title': {
            marginLeft: '28px',
            marginRight: '28px',
          },
          ...search?.results?.mobile,
        },
      }}
      id="search-results"
    >
      {props.isLoading ? (
        <div
          css={{
            height: '100px',
            position: 'relative',
            opacity: 0.5,
          }}
        >
          <LoadingIndicator style="dark" />
        </div>
      ) : (
        <>
          {props.title ? (
            <>
              <Heading style="heading5" as="h5" css={search?.title}>
                {props.title}
              </Heading>
              <Spacer s="s" />
            </>
          ) : null}
          {props.rateCodeSearchErrorMessage ? (
            <>
              <RateCodeUnavailableCard />
              <Spacer s="s" />
            </>
          ) : undefined}
          {props.children ? (
            <>
              {props.children}
              {props.resultRoomCount && props.resultRoomCount > 0 && (
                <OnlyDesktop>
                  <Spacer s="m" />
                  <RoomImageDisclaimer />
                  <Spacer s="xl" />
                </OnlyDesktop>
              )}
            </>
          ) : undefined}

          {props.resultRoomCount && (
            <ScreenReaderAnnouncement>
              {`${props.resultRoomCount} rooms available`}
            </ScreenReaderAnnouncement>
          )}
        </>
      )}
    </div>
  );
};
