import React from 'react';

import { Button } from '@/ui/controls';
import { Stack } from '@/ui/spacing';
import type { AvailableThemeKey } from '@/ui/theme';
import { useThemeSwitcherControls } from '@/ui/theme';
import { availableThemes } from '@/ui/theme/available-themes.const';

export const ThemeOverrideSwitcher = () => {
  const controls = useThemeSwitcherControls();

  return (
    <Stack>
      {(Object.keys(availableThemes) as AvailableThemeKey[]).map((themeKey) => (
        <Button
          key={themeKey}
          onClick={() => controls.setActiveThemeKey(themeKey)}
        >
          {themeKey === controls.activeThemeKey
            ? `${themeKey} (active)`
            : themeKey}
        </Button>
      ))}
      {controls.activeThemeKey !== controls.defaultThemeKey ? (
        <Button
          key="reset"
          onClick={() => controls.resetThemeKey()}
          // eslint-disable-next-line react/jsx-no-literals
        >
          Reset override
        </Button>
      ) : undefined}
    </Stack>
  );
};
