import { CountryCountry } from '@/em-api-client-typescript-fetch';

import type { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import de from './locale/de.json';
import enGB from './locale/en-GB.json';
import fr from './locale/fr.json';
import it from './locale/it.json';

export const hoxtonBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'hoxton',
  themeKey: 'hoxton',
  name: 'The Hoxton',
  // defaultHotelCode: 'hoxton.shoreditch-london',
  hotels: [
    {
      referenceId: 'hoxton.edinburgh-edinburgh',
      code: 'hoxton.edinburgh-edinburgh',
      name: 'Edinburgh',
      dialect: 'ohip',
      openingDate: '2025-06-23',
    },
    {
      referenceId: 'brussels',
      code: 'HB9D2',
      name: 'Brussels',
      openingDate: '2023-05-22',
      legacyTaxes: {
        isAdvanced: true,
      },
    },
    {
      referenceId: 'hoxton.charlottenburg-berlin',
      code: 'HB9D1',
      name: 'Charlottenburg',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.holborn-london',
      code: 'hoxton.holborn-london',
      name: 'Holborn',
      dialect: 'ohip',
    },
    {
      referenceId: 'shepherds-bush',
      code: 'HB9D5',
      name: "Shepherd's Bush",
    },
    {
      referenceId: 'hoxton.shoreditch-london',
      code: 'hoxton.shoreditch-london',
      name: 'Shoreditch',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.herengracht-amsterdam',
      code: 'HAMS',
      name: 'Amsterdam',
      dialect: 'ohip',
      legacyTaxes: {
        isAdvanced: true,
      },
    },
    {
      referenceId: 'hoxton.lloyd-amsterdam',
      code: 'hoxton.lloyd-amsterdam',
      name: 'Amsterdam, Lloyd',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.paris-paris',
      code: 'hoxton.paris-paris',
      name: 'Paris',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.southwark-london',
      code: 'hoxton.southwark-london',
      name: 'Southwark',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.williamsburg-newyork',
      code: 'THW',
      name: 'Williamsburg',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.portland-portland',
      code: '21850537',
      name: 'Portland',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.downtown-losangeles',
      code: '21853524',
      name: 'Los Angeles',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.chicago-chicago',
      code: '22023679',
      name: 'Chicago',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.vienna-vienna',
      code: 'hoxton.vienna-vienna',
      name: 'Vienna',
      dialect: 'ohip',
      openingDate: '2024-04-01',
    },
    {
      referenceId: 'hoxton.rome-rome',
      code: 'hoxton.rome-rome',
      name: 'Rome',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.poblenou-barcelona',
      code: 'hoxton.poblenou-barcelona',
      name: 'Poblenou',
      dialect: 'ohip',
    },
    {
      referenceId: 'hoxton.florence-florence',
      code: 'hoxton.florence-florence',
      name: 'Florence',
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://thehoxton.com',
  domains: [
    'localhost',
    'booking.thehoxton.local',
    'booking.dev.env.ennismore.com',
    'booking-sst.dev.env.ennismore.com',
    'booking-sst.staging.env.ennismore.com',
    'booking-sst.live.env.ennismore.com',
    'booking.thehoxton.com',
    'em-booking-ui-mt.vercel.app',
    'vercel:em-booking-ui-mt',
    'em-booking-ui.eu.ngrok.io',
    'booking.staging.env.ennismore.com',
    'booking-e2e.dev.env.ennismore.com',
  ],
  i18n: {
    locales: ['en-GB', 'en-US', 'it', 'de', 'fr'],
  },

  displayAccessibleRoomCheckbox: true,
  maxRoomCountPerBooking: 6,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 2,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 1,
      children: 3,
    },
    {
      adults: 1,
      children: 4,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 2,
      children: 3,
    },
    {
      adults: 3,
    },
    {
      adults: 3,
      children: 1,
    },
    {
      adults: 3,
      children: 2,
    },
    {
      adults: 4,
    },
    {
      adults: 4,
      children: 1,
    },
    {
      adults: 5,
    },
    {
      adults: 6,
    },
    {
      adults: 4,
      children: 2,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://thehoxton.com/privacy-policy/',
        cookiePolicy: 'https://thehoxton.com/cookie-policy/',
        termsOfUse: 'https://thehoxton.com/terms-of-use/',
        accessibility: 'https://thehoxton.com/accessibility/',
        contact: 'https://thehoxton.com/contact/',
      },
      it: {
        privacyPolicy: 'https://thehoxton.com/it/privacy-policy/',
        cookiePolicy: 'https://thehoxton.com/it/cookie-policy/',
        termsOfUse: 'https://thehoxton.com/it/terms-of-use/',
        accessibility: 'https://thehoxton.com/it/accessibility/',
        contact: 'https://thehoxton.com/it/contact/',
      },
      de: {
        privacyPolicy: 'https://thehoxton.com/de/privacy-policy/',
        cookiePolicy: 'https://thehoxton.com/de/cookie-policy/',
        termsOfUse: 'https://thehoxton.com/de/terms-of-use/',
        accessibility: 'https://thehoxton.com/de/accessibility/',
        contact: 'https://thehoxton.com/de/contact/',
      },
      fr: {
        privacyPolicy: 'https://thehoxton.com/fr/privacy-policy/',
        cookiePolicy: 'https://thehoxton.com/fr/cookie-policy/',
        termsOfUse: 'https://thehoxton.com/fr/terms-of-use/',
        accessibility: 'https://thehoxton.com/fr/accessibility/',
        contact: 'https://thehoxton.com/fr/contact/',
      },
    },
  },
  offersThirdPartyAuthorisationForm: true,
  isFlexyTimeEnabled: true,

  ohipDiscountCodeType: 'CORPORATE',

  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  hideAccessibleRoomsCheckbox: true,

  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],
  features: {
    bookingManagement: true,
  },
  translationOverrides: {
    'en-GB': enGB,
    it,
    de,
    fr,
  },
  showAveragePricePerNight: true,
  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-MB2L3WB' : 'GTM-KWCJXRH3',
    },
    vwo: {
      accountId: isProduction ? 570980 : 571567,
      goals: {
        bookingPurchase: isProduction ? 11 : 1,
      },
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '5428ae2c-56aa-4705-9ef4-fc4b6c7d0f58',
    },
  },
});
