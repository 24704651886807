import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@/box';
import { ErrorMessage } from '@/ui/form';

import type { SearchFormFields } from '../validators/search-form.validator';

export const SearchFormErrorMessage = ({ message }: { message?: string }) => {
  const {
    formState: { errors },
  } = useFormContext<Pick<SearchFormFields, 'from'>>();

  return (
    <Box
      flex={1}
      marginInlineStart={[0, 20]}
      marginTop={[20, 0]}
      marginBottom={[20, 0]}
      minHeight={20}
    >
      {/* Might be worthwhile to move the check-in date (from) error message
      underneath the field vs here - better approach for accessibility sake.
      Handling it here at the moment due to design requirements. */}
      <ErrorMessage message={message || errors.from?.message} />
    </Box>
  );
};
