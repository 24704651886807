import { Box, StyleProps } from '@chakra-ui/react';
import React from 'react';

type ChakraProps = React.ComponentProps<typeof Box>;

export type BoxProps = Partial<{
  [key in StyleKeys]: StyleProps[key];
}> & { children?: any; className?: string; role?: string };

export type ValueType = string | number;

/**
 * What on earth is going on here?
 * -
 * Temporary solution to begin the migration of the Box component to Chakra UI instead of Rebass/Reflexbox.
 * Maintaining a list of keys we're using directly in our Box component to lock down too broad a usage.
 * If adding to this list, make sure that it supports the transformation that happens in transformProps() below.
 * See: https://ennismore.atlassian.net/browse/DEV-2762
 */
const responsiveStyleKeys = [
  'width',
  'justifySelf',
  'maxWidth',
  'maxHeight',
  'padding',
  'flexDirection',
  'margin',
  'height',
  'marginLeft',
  'marginTop',
  'marginBottom',
  'marginRight',
  'alignItems',
  'flexWrap',
  'flexShrink',
  'display',
  'position',
  'alignSelf',
  'justifyContent',
  'flex',
  'paddingTop',
  'paddingLeft',
  'paddingRight',
  'paddingBottom',
  'minWidth',
  'minHeight',
  'mb',
  'gap',
  'rowGap',
  'columnGap',
  'backgroundColor',
  'textAlign',
  'gridTemplateColumns',
  'marginInlineEnd',
  'marginInlineStart',
  'marginBlockStart',
  'marginBlockEnd',
  'paddingInlineEnd',
  'paddingInlineStart',
  'paddingBlockStart',
  'paddingBlockEnd',
] as const;
export type StyleKeys = (typeof responsiveStyleKeys)[number];

const transformPropValue = (value: ValueType) => {
  if (typeof value !== 'number') {
    return value;
  }

  // If the supplied number value is greater than 1, map it to a percentage
  if (value <= 1) {
    return `${value * 100}%`;
  }

  return `${value}px`;
};

const transformMaybeArrayProp = (prop: ValueType | ValueType[]) =>
  Array.isArray(prop) ? prop.map(transformPropValue) : transformPropValue(prop);

const ignoreTransform = ['flex', 'children'] as const;

/**
 * Why is this here?
 *
 * We used to use a component primitive package called reflexbox/rebass, but it hasn't been touched in 3 years and doesn't support React 17/18.
 * Chakra has an (almost) drop-in replacement for reflexbox. It works 1-1, except for different behaviour when passing an integer as a unit prop.
 * We're adding that support here ourselves, with plans to deprecate it piece-by-piece.
 *
 */
export const transformProps = (props: BoxProps): ChakraProps => {
  const transformedProps: ChakraProps = {};

  Object.entries(props).forEach(([key, value]) => {
    // If this key is in the ignore list, skip transformation
    if (ignoreTransform.includes(key as any)) {
      transformedProps[key] = value;
      return;
    }

    transformedProps[key] = transformMaybeArrayProp(value);
  });

  return transformedProps;
};
