import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { useLocale } from '@/i18n';

import { trackPageView } from '../gtm';

/**
 * Called whenever the route changes
 * @param path The active path
 */
const createRouteChangeHandler = (locale: string) => (path: string) => {
  trackPageView({ path, referrer: document.referrer, locale });
};

export const usePageLoadTracker = () => {
  const router = useRouter();
  const locale = useLocale();
  const handleRouteChange = useMemo(
    () => createRouteChangeHandler(locale.baseName),
    [locale.baseName]
  );

  useEffect(() => {
    // Firstly, trigger the page load once
    handleRouteChange(router.asPath);

    // Subscribe to future path change events from Next's Router
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [handleRouteChange, router, router.asPath]);
};
